import {
  DialogTitle as DialogTitleBase,
  DialogTitleProps,
  IconButton,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import theme from '@utils/theme'

type CustomDialogTitleProps = DialogTitleProps & {
  title: string
  onClose: () => void
  format?: boolean
}

export function DialogTitle({
  title,
  onClose,
  format = false,
  ...other
}: CustomDialogTitleProps) {
  return (
    <DialogTitleBase
      style={{
        margin: 0,
        height: 30,
        backgroundColor: theme.palette.primary.main,
        border: 'none,',
        color: '#fff',
      }}
      {...other}
    >
      <Typography variant="h6">{title}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#fff',
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitleBase>
  )
}
