import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { Button, Container, Dialog, DialogContent, Grid } from '@mui/material'
import moment from 'moment'
import { capitalize } from 'lodash'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { PatientTransportStatus } from '../../../services/api'
import { findMedicalOrders } from '../../../state/thunks/medicalOrderThunk'
import './MedicalOrderList.css'
import { renderMedicalOrderPickupStatus, TransportForm } from './TransportForm'
import { setMedicalOrderFilters } from '../../../state/reducers/medicalOrderReducer'
import { DialogTitle } from '@components/titles'
import { AppToolbar } from '@components/Toolbar'
const AssignMedicalOrder = () => {
  const [selectedId, setSelectedId] = useState<number>(-1)

  const medicalOrders = useAppSelector(
    (state) => state.medicalOrder.medicalOrders,
  )
  const filters = useAppSelector((state) => state.medicalOrder.filters)
  const dispatch = useAppDispatch()

  const columns: GridColumns = [
    {
      field: 'accessionNumber',
      headerName: 'N° d\'examen',
      width: 160,
    },
    {
      field: 'patient.firstName',
      headerName: 'Prénom',
      width: 160,
      valueGetter: (params) => capitalize(params.row.patient.firstName),
    },
    {
      field: 'patient.lastName',
      headerName: 'Nom',
      width: 160,
      valueGetter: ({ row }) => {
        return capitalize(row.patient.lastName)
      },
    },
    {
      field: 'patient.birthDate',
      headerName: 'Date de naissance',
      width: 160,
      valueGetter: ({ row }) => {
        return moment(row.patient.birthDate).format('DD/MM/YYYY')
      },
    },
    {
      field: 'exam.label',
      headerName: 'Examen',
      width: 260,
      valueGetter: ({ row }) => {
        return `${capitalize(row.exam.label)} (${row.exam.code})`
      },
    },
    {
      field: 'plannedDate',
      headerName: 'Heure RDV',
      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {moment(params.row.plannedDate).format('HH:mm')}
          </div>
        )
      },
    },
    {
      field: 'pickupTime',
      headerName: 'Passage à',
      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {params.row.pickupTime
              ? moment(params.row.pickupTime).format('HH:mm')
              : ''}
          </div>
        )
      },
    },
    {
      field: 'patientTransport',
      headerName: 'Statut Transport',
      width: 280,
      renderCell: (params) => {
        return renderMedicalOrderPickupStatus(params.row)
      },
    },
    {
      field: 'actions',
      headerName: 'Transports',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setSelectedId(params.row.id)
              }}
              sx={{
                backgroundColor: '#f5f5f5',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
              size="small"
            >
              {params.row.patientTransport
                ? params.row.patientTransport.status ===
                  PatientTransportStatus.Called
                  ? 'Voir'
                  : 'Modifier / Appeller'
                : 'Ajouter'}
            </Button>
          </>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(findMedicalOrders({ includeTransport: true, isTodayOnly: true }))
  }, [filters.page, filters.search, filters.siteCode, filters.limit])

  useEffect(() => {
    return () => {
      dispatch(
        setMedicalOrderFilters({
          ...filters,
          page: 0,
          siteCode: '',
          search: '',
        }),
      )
    }
  }, [])

  if (!medicalOrders) {
    return <></>
  }
  const selectedMedicalOrder = useMemo(
    () => medicalOrders.find((mo) => mo.id === selectedId),
    [selectedId, medicalOrders],
  )

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '95vh' }}>
        <Grid item container>
          <DataGridPro
            density="standard"
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '0px 25px 25px 25px',
            }}
            columns={columns}
            rows={medicalOrders}
            getRowClassName={(params) =>
              params.row.patientTransport
                ? params.row.patientTransport.status ===
                  PatientTransportStatus.Called
                  ? 'called-row'
                  : 'uncalled-row'
                : ''
            }
            rowCount={filters.total}
            disableSelectionOnClick
            pagination
            paginationMode="server"
            disableMultipleSelection
            onPageChange={(page) => {
              dispatch(setMedicalOrderFilters({ ...filters, page }))
            }}
            initialState={{
              pinnedColumns: { left: [''], right: ['actions'] },
            }}
            components={{
              Toolbar: AppToolbar,
            }}
            pageSize={filters.limit}
            onPageSizeChange={(pageSize) => {
              dispatch(setMedicalOrderFilters({ ...filters, limit: pageSize }))
            }}
            rowsPerPageOptions={[20, 50, 100]}
          />
        </Grid>
      </Grid>
      {/* add dialog title with close button */}
      <Dialog
        open={selectedId !== -1}
        onClose={() => setSelectedId(-1)}
        maxWidth="md"
        fullWidth
        // paperprops border radius
        PaperProps={{
          sx: {
            borderRadius: 5,
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <DialogTitle
          title="Transport"
          onClose={() => setSelectedId(-1)}
          format
        />
        <DialogContent>
          {!!selectedMedicalOrder && (
            <TransportForm row={selectedMedicalOrder} />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default AssignMedicalOrder
