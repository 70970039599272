import React, { useEffect, useState, useCallback } from 'react'
import { Divider, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography, FormHelperText, Checkbox, Button, styled, DialogActions, backdropClasses } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import 'moment/locale/fr'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ReactAutocomplete from 'react-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { PatientDto, RppsDto } from '@services/api'
import { formatSsn, formatSsnPrefix } from '@utils/ssnUtil'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import { DatePicker } from '@mui/lab'
import { BanService } from '@services/BanServices'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

type PatientInit = {
  id: string
  sex: 'M' | 'F' | 'O',
  firstName: string
  lastName: string
  maidenName: string
  birthDate: Date
  phoneNumber: string
  email: string
  address: string
  birthPlace: string
  birthPlaceDepartment: string
  ssn: string
  weight: number
  height: number
  familyDoctor: RppsDto
  comments: string
}

export const patientFormSchema = yup.object().shape({
  sex: yup.string().required('Merci de choisir une civilité'),
  firstName: yup.string().required('Champ requis'),
  lastName: yup.string().required('Champ requis'),
  maidenName: yup.string().optional(),
  birthDate: yup.date().required('Champ requis')
    .min(new Date(1900, 0, 1), 'Date de naissance doit être > 1900')
    .max(new Date(new Date().getTime() + 86400000), 'Date de naissance doit être <= aujourd\'hui'),
  phoneNumber: yup.string().required('Champ requis'),
  email: yup.string().optional().email('Email non valide'),
  address: yup.string().required('Champ requis'),
  birthPlace: yup.string().optional(),
  birthPlaceDepartment: yup.string().nullable().optional(),
  ssn: yup.string().optional(),
  weight: yup.number()
    .required('Champ requis')
    .min(1, 'Poids doit être > 1')
    .max(400, 'Poids doit être < 400'),
  height: yup.number()
    .required('Champ requis')
    .min(1, 'Taille doit être > 1')
    .max(300, 'Taille doit être < 300'),
  familyDoctor: yup.object().required('Champ requis'),
  comments: yup.string().optional().max(500, 'Commentaires doit être < 500'),
  files: yup.array().optional()
})

export const FormAddPatient = ({ onClose }) => {

  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<PatientInit>({
    resolver: yupResolver(patientFormSchema)
  })

  console.log(errors)

  const date = new Date()

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [maidenName, setMaidenName] = useState('')
  const [maidenNameVisible, setMaidenNameVisible] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  
  const [ssn, setSsn] = useState('')
  const [ssnPrefix, setSsnPrefix] = useState('')
  const [ssnStatus, setSsnStatus] = useState(false)
  
  const [sex, setSex] = useState('M')
  const birthDate = watch('birthDate')
  
  const [email, setEmail] = useState('')
  const [weight, setWeight] = useState('')
  const [height, setHeight] = useState('')
  const [comments, setComments] = useState('')
  const [familyDoctor, setFamilyDoctor] = useState<RppsDto | null>(null)
  
  const [isManualAddressVisible, setIsManualAddressVisible] = useState(false)
  const [addressSearch, setAddressSearch] = useState('')
  const [address, setAddress] = useState('')
  const [addressOptions, setAddressOptions] = useState([{ label: '', value: ''}])
  
  const [isManualBirthPlaceVisible, setIsManualBirthPlaceVisible] = useState(false)
  const [birthPlaceSearch, setBirthPlaceSearch] = useState('')
  const [birthPlace, setBirthPlace] = useState('')
  const [birthPlaceExtra, setBirthPlaceExtra] = useState('')
  const [birthPlaceOptions, setBirthPlaceOptions] = useState([{ label: '', value: ''}])
  
  const BanServices = new BanService()

  const useStyles = makeStyles({
    phoneInput: {
      '& .PhoneInputInput': {
        backgroundColor: 'transparent',
        border: 'none',
        textColor: 'black',
        height: '100%',
      },
    },
  })
  
  const onSubmit = data => console.log(data)
  const classes = useStyles()

  useEffect(() => {
    setValue('ssn', `${ssnPrefix}${ssn.replace(/\s/g, '')}`)
  }, [ssn, ssnPrefix, setValue])

  useEffect(() => {
    if (familyDoctor) {
      setValue('familyDoctor', familyDoctor)
    }
  }, [familyDoctor, setValue])

  useEffect(() => {
    setValue('height', Number(height))
  }, [height, setValue])

  useEffect(() => {
    setValue('weight', Number(weight))
  }, [weight, setValue])

  useEffect(() => {
    if (isManualAddressVisible) {
      setValue('birthPlaceDepartment', `${birthPlaceExtra}`)
    }
    setValue('birthPlace', birthPlace)
  }, [birthPlace, birthPlaceExtra, setValue])

  const setIsManualBirthPlaceVisibleAndRemouve = (checked: boolean) => {
    setIsManualBirthPlaceVisible(checked)
    if (!checked) {
      setBirthPlaceExtra('')
    }
  }

  // add debounce 'lodash' to the search 500ms after the last key stroke to call the api
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      BanServices.searchFullAddress(searchTerm)
        .then((data) => {
          if (data.length > 0) {
            const storeData = data.map((d) => ({
              label: d.place,
              value: d.id
            }))
            setAddressOptions(storeData)
          }
        }) 
        .catch((error) => {
          console.error('Failed to fetch addresses:', error)
        })
    }, 500),
    []
  )
  
  useEffect(() => {
    debouncedSearch(addressSearch)
  }, [addressSearch, debouncedSearch])

  const debouncedSearchBirth = useCallback(
    debounce((searchTerm) => {
      BanServices.search(searchTerm)
        .then((data) => {
          if (data.length > 0) {
            const storeData = data.map((d) => ({
              label: d.place,
              value: d.id
            }))
            setBirthPlaceOptions(storeData)
          }
        }) 
        .catch((error) => {
          console.error('Failed to fetch addresses:', error)
        })
    }, 500),
    []
  )
  
  useEffect(() => {
    debouncedSearchBirth(birthPlaceSearch)
  }, [birthPlaceSearch, debouncedSearchBirth])

  useEffect(() => {
    setMaidenNameVisible(sex === 'F')
  }, [sex])

  useEffect(() => {
    if (birthDate && birthPlace && sex && birthPlace.slice(0, 2).match(/^\d{2}$/) && birthPlace.length >= 2){
      setSsnStatus(true)
    } else {
      setSsnPrefix('') 
      setSsnStatus(false)
    }
    if (ssnStatus) {
      const gender = sex === 'M' ? '1' : '2'
      const birthMonth = birthDate === null ? null : birthDate.getMonth() + 1
      const birthMonth2 = birthMonth === null ? null : birthMonth < 10 ? '0' + birthMonth : birthMonth
      const birthYear = birthDate === null ? null : birthDate.getFullYear().toString().slice(2, 4)
      const department = birthPlace.slice(0, 2)
      setSsnPrefix(gender + birthYear + birthMonth2 + department)
    }
  }, [sex, birthDate, birthPlace, ssnStatus])

  const onAddressChange = (value: string) => {
    const addr = value.replace(/\s/g, '-')
    setAddressSearch(addr)
    setAddress(addr)
    setValue('address', `${address}`)
  }
  
  const onBirthPlaceChange = (value: string) => {
    const addr = value.replace(/\s/g, '-')
    setBirthPlaceSearch(addr)
    setBirthPlace(addr)
  }
  
  // const handleBirthDateChange = (date: Date | null) => {
  //   console.log(date) 
  //   setValue('birthDate', moment(birthDate).toDate())
  //   setBirthDate(date)
  // }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <Divider>Informations nouveau patient</Divider>
        </Grid>
        {/* Civilité */}
        <Grid item xs={12}>
          <RadioGroup
            row
            {...register('sex')}
            aria-labelledby="sexe"
            defaultValue={'M'}
            value={sex}
            name='sex'
            onChange={(e) => setSex(e.target.value)}
          >
            <FormControlLabel
              style={{ color: '#48bef9', fontWeight: 'bold' }}
              value="M"
              control={
                <Radio
                  sx={{
                    color: '#48bef9',
                    '&.Mui-checked': {
                      color: '#48bef9'
                    }
                  }}
                />
              }
              label="Mr"
            />
            <FormControlLabel
              value="F"
              style={{ color: '#f48dde', fontWeight: 'bold' }}
              control={
                <Radio
                  sx={{
                    color: '#f48dde',
                    '&.Mui-checked': {
                      color: '#f48dde'
                    }
                  }}
                />
              }
              label="Mme"
            />
          </RadioGroup>
        </Grid>
        {/* Prénom */}
        <Grid item xs={sex === 'M' ? 6 : sex === 'F' ? 4 : 6}>
          <TextField
            label="Prénom"
            variant="outlined"
            {...register('firstName')}
            fullWidth
            value={name}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => setName(e.target.value)}
          />
          {errors?.firstName && <FormHelperText error>{errors?.firstName?.message}</FormHelperText>}
        </Grid>
        {/* Nom */}
        <Grid item xs={sex === 'M' ? 6 : sex === 'F' ? 4 : 6}>
          <TextField
            label="Nom"
            {...register('lastName')}
            variant="outlined"
            fullWidth
            value={lastName}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => setLastName(e.target.value)}
          />
          {errors?.lastName && <FormHelperText error>{errors?.lastName?.message}</FormHelperText>}
        </Grid>
        {/* Nom de jeune fille (si sexe féminin) */}
        {maidenNameVisible && (
          <Grid item xs={4}>
            <TextField
              label="Nom de jeune fille"
              name='maidenName'
              variant="outlined"
              fullWidth
              value={maidenName}
              inputProps={{ maxLength: 35 }}
              onChange={(e) => setMaidenName(e.target.value)}
            />
            {errors?.maidenName && <FormHelperText error>{errors?.maidenName?.message}</FormHelperText>}
          </Grid>
        )}
        {/* Date de naissance */}
        <Grid item xs={4}>
          <DatePicker
            value={moment(birthDate)}
            onChange={(ev) => {
              setValue('birthDate', ev ? ev.toDate() : new Date())
            }}
            inputFormat="DD/MM/YYYY"
            openTo="year"
            views={['year', 'month', 'day']}
            disableFuture
            label={<FormattedMessage id="birthDate" defaultMessage="Date de naissance" />}
            renderInput={(params) => <TextField {...params} sx={{ width: '100%' }}/>}
          />
          {errors?.birthDate && <FormHelperText error>{errors?.birthDate?.message}</FormHelperText>}
        </Grid>
        {/* Numéro de téléphone */}
        {/* suprimer le style de l'input */}
        <Grid item xs={4}>
          <PhoneInput
            {...register('phoneNumber')}
            name="phoneNumber"
            labels={{ number: 'Numéro de ' }}
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="FR"
            international
            style={{
              height: '34px',
              fontSize: '1em',
              border: '1px solid #ADB1BA',
              backgroundColor: 'transparent',
              borderRadius: '3px',
              padding: '10px',
            }}
            className={classes.phoneInput}
          />
          {errors?.phoneNumber && <FormHelperText error>{errors?.phoneNumber?.message}</FormHelperText>}
        </Grid>
        {/* Email */}
        <Grid item xs={4}>
          <TextField
            label="Email"
            variant="outlined"
            type='email'
            fullWidth
            value={email}
            {...register('email')}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        {/* Adresse */}
        <Grid item xs={4}>
          <Grid
            container
            sx={{
              paddingRight: '22px'
            }}
          >
            {!isManualAddressVisible && (
              <>
                <ReactAutocomplete
                  {...register('address')}
                  wrapperStyle={{
                    width: '100%'
                  }}
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: '#fff',
                    padding: '10px',
                    fontSize: '1em',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    zIndex: 100
                  }}
                  inputProps={{
                    style: {
                      width: '100%',
                      height: '34px',
                      fontSize: '1em',
                      border: '1px solid #ADB1BA',
                      backgroundColor: 'transparent',
                      borderRadius: '3px',
                      padding: '10px'
                    },
                    disabled: isManualAddressVisible,
                    placeholder: 'Rechercher une addresse'
                  }}
                  getItemValue={(item) => item.label}
                  items={addressOptions}
                  renderItem={(item: any, isHighlighted: any) => (
                    <div
                      style={{
                        background: isHighlighted ? 'lightblue' : 'white',
                        cursor: 'pointer',
                        padding: '5px'
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                  value={addressSearch}
                  onChange={(e) => setAddressSearch(e.target.value.replace(/\s/g, '-'))}
                  onSelect={onAddressChange}
                />
                {errors?.address && <FormHelperText error>{errors?.address?.message}</FormHelperText>}
              </>
            )}
          </Grid>

          {isManualAddressVisible && (
            <Grid container>
              <Grid xs={12}>
                <TextField
                  variant="outlined"
                  {...register('address')}
                  label={<FormattedMessage id="adress" defaultMessage="Adresse" />}
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  error={Boolean(errors?.address)}
                  helperText={errors?.address?.message}
                  defaultValue={address}
                  InputLabelProps={{ shrink: true }}
                  placeholder='Ex: "1 rue de la paix, 75000 Paris"'
                />
              </Grid>
              {errors?.address && <FormHelperText error>{errors?.address?.message}</FormHelperText>}
            </Grid>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isManualAddressVisible}
                onChange={(e) => setIsManualAddressVisible(e.target.checked)}
                color="primary"
              />
            }
            label={isManualAddressVisible ? 'Retour à la recherche' : 'Vous ne trouvez pas la bonne adresse ?'}
          />
        </Grid>
        {/* Lieu de naissance */}
        <Grid item xs={4}>
          <Grid
            container
            sx={{
              paddingRight: '22px'
            }}
          >
            {!isManualBirthPlaceVisible && (
              <>
                <ReactAutocomplete
                  {...register('birthPlace')}
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: '#fff',
                    padding: '10px',
                    fontSize: '1em',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    zIndex: 100
                  }}
                  inputProps={{
                    style: {
                      width: '100%',
                      height: '34px',
                      fontSize: '1em',
                      border: '1px solid #ADB1BA',
                      backgroundColor: 'transparent',
                      focus: 'black',
                      borderRadius: '3px',
                      padding: '10px'
                    },
                    hover: {
                      border: '1px solid #000000de',
                    },
                    disabled: isManualBirthPlaceVisible,
                    placeholder: 'Lieu de naissance'
                  }}
                  wrapperStyle={{ width: '100%' }}
                  getItemValue={(item) => item.label}
                  items={birthPlaceOptions}
                  renderItem={(item: any, isHighlighted: any) => (
                    <div
                      style={{
                        background: isHighlighted ? 'lightblue' : 'white',
                        cursor: 'pointer',
                        padding: '5px'
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                  value={birthPlaceSearch}
                  onChange={(e) => setBirthPlaceSearch(e.target.value.replace(/\s/g, '-'))}
                  onSelect={onBirthPlaceChange}
                />
              </>
            )}
          </Grid>
          {isManualBirthPlaceVisible && (
            <Grid container spacing={1} justifyItems="space-between">
              <Grid item xs={8}>
                <TextField
                  {...register('birthPlace')}
                  variant="outlined"
                  label={<FormattedMessage id="birthPlace" defaultMessage="Lieu de naissance" />}
                  fullWidth
                  value={birthPlace}
                  onChange={(e) => setBirthPlace(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder='Ex: "Paris"'
                  inputProps={{ maxLength: 35 }}
                />
              </Grid>
              {errors?.birthPlace && <FormHelperText error>{errors?.birthPlace?.message}</FormHelperText>}
              <Grid item xs={4}>
                <TextField
                  {...register('birthPlaceDepartment')}
                  variant="outlined"
                  label="Departement"
                  placeholder='Ex: "75"'
                  type='number'
                  fullWidth
                  value={birthPlaceExtra}
                  onChange={(e) => setBirthPlaceExtra(e.target.value)}
                  inputProps={{ maxLength: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {errors?.birthPlaceDepartment && <FormHelperText error>{errors?.birthPlaceDepartment?.message}</FormHelperText>}
            </Grid>
          )}
          <Grid item justifyContent="space-between" container>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isManualBirthPlaceVisible}
                  onChange={(e) => setIsManualBirthPlaceVisibleAndRemouve(e.target.checked)}
                  color="primary"
                />
              }
              label={isManualBirthPlaceVisible ? 'Retour à la recherche' : 'Introuvable / Né(e) à l\'étranger ?'}
            />
            {isManualBirthPlaceVisible && (
              <Typography variant="caption" sx={{ fontSize: '0.75rem', color: 'grey', textAlign: 'center' }}>
                <FormattedMessage id="birthPlaceTip" defaultMessage="99 pour l'étranger" />
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Numéro de sécurité sociale */}
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            // {...register('ssn')}
            label={<FormattedMessage id="ssn" defaultMessage="Numéro de sécurité sociale - 15 charactères dont la clé" />}
            fullWidth
            onChange={(e) => setSsn(e.target.value)}
            value={formatSsn(ssn)}
            error={Boolean(errors?.ssn)}
            defaultValue={formatSsn(ssn)}
            onKeyPress={(event) => {
              const key = event.key
              if (!/\d/.test(key) && key !== 'Backspace' && key !== 'Delete') {
                event.preventDefault()
              }
            }}
            InputProps={{
              inputProps: {
                maxLength: 10
              },
              disabled: !ssnStatus,
              startAdornment:
            ssnPrefix ? (
              <InputAdornment position="start">{formatSsnPrefix(ssnPrefix)}</InputAdornment>
            ) : (
              <></>
            )
            }}
            helperText={errors?.ssn?.message}
            InputLabelProps={{ shrink: true }}
          />
          {errors?.ssn && <FormHelperText error>{errors?.ssn?.message}</FormHelperText>}
        </Grid>
        {/* Poids */}
        <Grid item xs={3}>
          <TextField
            {...register('weight')}
            label="Poids"
            name='weight'
            variant="outlined"
            type='number'
            fullWidth
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
            inputProps={{
              min: 0,
              max: 400,
              inputMode: 'numeric',
            }}
          />
          {errors?.weight && <FormHelperText error>{errors?.weight?.message}</FormHelperText>}
        </Grid>
        {/* Taille */}
        <Grid item xs={3}>
          <TextField
            {...register('height')}
            label="Taille"
            name='height'
            variant='outlined'
            type='number'
            fullWidth
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
            inputProps={{
              min: 0,
              max: 300,
              inputMode: 'numeric',
            }}
          />
          {errors?.height && <FormHelperText error>{errors?.height?.message}</FormHelperText>}
        </Grid>
        {/* Médecin traitant */}
        <Grid item xs={6}>
          {/* Composant d'autocomplétion pour le médecin traitant */}
          <RppsAutocompleteByNameInput
            {...register('familyDoctor')}
            label={<FormattedMessage id="familyDoctor" defaultMessage="Médecin traitant" />}
            onChange={(value) => setFamilyDoctor(value)}
            value={familyDoctor}
          />
          {errors?.familyDoctor && <FormHelperText error>Champ requis</FormHelperText>}
        </Grid>
        {/* Commentaires */}
        <Grid item xs={12}>
          <TextField
            {...register('comments')}
            label="Comments"
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            fullWidth
            multiline
            rows={4}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
          {errors?.comments && <FormHelperText error>{errors?.comments?.message}</FormHelperText>}
        </Grid>
      </Grid>
      <DialogActions
        sx={{
          paddingBottom: '20px',
          paddingTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <Button
          style={{ color: 'white' }}
          sx={{ borderRadius: 5 }}
          variant="contained"
          color="error"
          fullWidth
          onClick={() => onClose()}
        >
            Annuler
        </Button>
        <Button
          style={{ color: 'white' }}
          variant="contained"
          color='success'
          fullWidth
          type="submit"
          sx={{ borderRadius: 5 }}
        >
            Confirmer
        </Button>
      </DialogActions>
    </form>
  )
}
  