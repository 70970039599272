import { BrowserRouter, Routes, Route } from 'react-router-dom'

import AppLayout, { StyledMain } from '../layouts/appLayout'
import AuthLayout from '../layouts/authLayout'
import Profile from './app/Profile'
// import Home from './app/Home'
// import Sites from './app/site/Sites'
// import Clients from './app/client/Clients'
// import Preferences from './app/preferences/Preferences'
// import Members from './app/member/Members'
// import Equipments from './app/equipment/Equipments'
// import Exams from './app/procedure/Exams'
import Login from './auth/Login'
import ForgottenPassword from './auth/ForgottenPassword'
import ResetPassword from './auth/ResetPassword'
import VerificationEmail from './auth/VerificationEmail'
import NotFound from './auth/NotFound'
import Ambulances from './app/ambulance/Ambulances'
// import Rooms from './app/room/Rooms'
// import Roles from './app/role-permission/Roles'
// import Questions from './app/questions/Questions'
// import Stats from './app/stats/Stats'
// import Billing from './app/billing/Billing'
// import InsiView from './app/billing/InsiView'
// import Logs from './app/log/Logs'
import MedicalOrderTransport from './app/ambulance/MedicalOrderTransport'
// import Worklists from './app/Worklists/Worklists'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forgotten-password" element={<ForgottenPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="verification-mail" element={<VerificationEmail />} />
        </Route>

        <Route path="/" element={<AppLayout />}>
          {/* <Route index element={<Home />} /> */}
          <Route path="profile" element={<Profile />} />
          <Route path="sa">
            {/* <Route index element={<Home />} /> */}

            {/* <Route path="clients" element={<Clients />}> */}
            {/*   <Route path=":clientId" element={<Preferences />} /> */}
            {/* </Route> */}
          </Route>
          <Route path="/">
            <Route path="" element={<Ambulances />} />
          </Route>
          <Route path="m">
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="worklist" element={<Worklists />} /> */}
            {/* <Route path="rooms" element={<Rooms />} /> */}
            {/* <Route path="roles" element={<Roles />} /> */}
            {/* <Route path="equipment" element={<Equipments />} /> */}
            {/* <Route path="equipements" element={<Equipments />} /> */}
            {/* <Route path="logs" element={<Logs />} /> */}
            {/* <Route path="members" element={<Members />} /> */}
            {/* <Route path="procedures" element={<Exams />} /> */}
            {/* <Route path="questions" element={<Questions />} /> */}
            {/* <Route path="preferences" element={<Preferences />} /> */}
            {/* <Route path="sites" element={<Sites />}></Route> */}
            {/* <Route path="billing" element={<Billing />}></Route> */}
            {/* <Route path="insi" element={<InsiView />}></Route> */}
            {/**/}
            {/* <Route path="preferences" element={<Preferences />} /> */}
            {/* <Route path="stats" element={<Stats />} /> */}
            {/* <Route path="sites" element={<Sites />}></Route> */}
          </Route>
        </Route>
        <Route
          path="assign-transport"
          element={
            <StyledMain>
              <MedicalOrderTransport />
            </StyledMain>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
