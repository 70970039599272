export default class StorageService {
  static getAuthToken() {
    return localStorage.getItem('authToken')
  }
  static setAuthToken(token: string) {
    localStorage.setItem('authToken', token)
  }
  static removeAuthToken() {
    localStorage.removeItem('authToken')
  }
  static setApiKey(key: string) {
    localStorage.setItem('apiKey', key)
  }
  static getApiKey() {
    return localStorage.getItem('apiKey')
  }
}
