import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AmbulanceCompanyDto, PatientTransportDto } from '../../services/api'
import {
  createAmbulanceCompany,
  deleteAmbulanceCompany,
  deletePatientTransports,
  findAmbulanceCompanies,
  findPatientTransports,
  updatePatientTransport,
} from '../thunks/ambulanceThunk'
import { SetAmbulanceFilterAction } from '../actions'

export interface AmbulanceState {
  data: AmbulanceCompanyDto[]
  filters: {
    page: number
    limit: number
    total: number
    siteId?: number
    search?: string
    siteCode?: string
  }
  patientTransports: PatientTransportDto[]
  patientTransportFilters: {
    page: number
    limit: number
    total: number
    companyId?: number
    search?: string
  }
}

const initialState: AmbulanceState = {
  data: [],
  filters: {
    page: 0,
    limit: 20,
    total: 0,
  },
  patientTransports: [],
  patientTransportFilters: {
    page: 1,
    limit: 20,
    total: 0,
  },
}

const ambulanceSlice = createSlice({
  name: 'ambulance',
  initialState,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<Partial<SetAmbulanceFilterAction>>,
    ) => {
      console.log('Reset filters ', action)
      state.filters = { ...state.filters, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAmbulanceCompanies.fulfilled, (state, { payload }) => {
      state.data = payload.datas
      state.filters.total = payload.totalCount
    })
    builder.addCase(createAmbulanceCompany.fulfilled, (state, { payload }) => {
      state.data = [payload, ...state.data]
    })
    builder.addCase(deleteAmbulanceCompany.fulfilled, (state, { payload }) => {
      state.data = state.data.filter((d) => !payload.includes(d.id))
    })
    builder.addCase(findPatientTransports.fulfilled, (state, { payload }) => {
      state.patientTransports = payload.datas
      state.patientTransportFilters.total = payload.totalCount
    })
    builder.addCase(updatePatientTransport.fulfilled, (state, { payload }) => {
      state.patientTransports = state.patientTransports.map((d) => {
        if (d.id === payload.id) return payload
        return d
      })
    })
    builder.addCase(deletePatientTransports.fulfilled, (state, { payload }) => {
      state.patientTransports = state.patientTransports.filter(
        (d) => !payload.includes(d.id),
      )
    })
  },
})

export const { setFilters } = ambulanceSlice.actions

export default ambulanceSlice.reducer
