/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'querystring'

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any
  headers?: any
  url?: any
  data?: any
  params?: any
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void,
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any,
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url }
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  }
  return configs
}

export const basePath = ''

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[]
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[]
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number
  items?: T[]
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number
  items?: T[]
}

/**
 * NEW ROOT STATS ------------------------------------------------------------------------------------------
 */

export interface DugnutStatsData {
  labels: string[]
  total: number[]
}

export const convertedDataForCharts = (data): Promise<DugnutStatsDataDti> => {
  const result = data.reduce(
    (acc, { label, total }) => {
      acc.labels.push(label)
      acc.total.push(total)
      return acc
    },
    { labels: [], total: [] },
  )
  return Promise.resolve(result)
}

export interface ParamStatsGetAllPrescriber {
  startYear: number
  endYear: number
  siteId?: number
}

export interface ParamStatsGetTopEvent {
  accountType: string
  eventType: string
  siteId?: number
}

export interface DugnutStatsDataDti {
  labels: string[]
  total: number[]
}

export interface TableStatsPrescriberDti {
  rank: number
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  skill: string
  total: number
}

export interface TableStatsUserDti {
  rank: number
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  total: number
  firstName: 'string'
  lastName: 'string'
  email: 'string'
  phone: 'string'
  total: 0
}

export class StatsServices {
  /**
   *  /stats/top/prescriptor
   */
  static fetchAllPrescriber(
    params: {
      startYear: number
      endYear: number
      siteId?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<TableStatsPrescriberDti[]> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/stats/top/prescriber?startYear={Year1}&endYear={Year2}'
      if (params['siteId']) {
        url += '&siteId={id}'
        url = url.replace('{Year1}', params['startYear'] + '')
        url = url.replace('{Year2}', params['endYear'] + '')
        url = url.replace('{id}', params['siteId'] + '')
      } else {
        url = url.replace('{Year1}', params['startYear'] + '')
        url = url.replace('{Year2}', params['endYear'] + '')
      }

      console.log(url)

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *  /stats/examtype/breakdown
   */
  static fetchAllExamBreakdown(
    params: {
      siteId?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/stats/examtype/breakdown'
      if (params['siteId']) {
        url += '?siteId={id}'
        url = url.replace('{id}', params['siteId'] + '')
      }
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *  /stats/top/event
   */
  static fetchAllTopEvent(
    params: {
      accountType: string
      eventType: string
      siteId?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<TableStatsUserDti[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/stats/top/event?accountType={AT}&eventType={ET}'
      if (params['siteId']) {
        url += '&siteId={id}'
        url = url.replace('{AT}', params['accountType'] + '')
        url = url.replace('{ET}', params['eventType'] + '')
        url = url.replace('{id}', params['siteId'] + '')
      } else {
        url = url.replace('{AT}', params['accountType'] + '')
        url = url.replace('{ET}', params['eventType'] + '')
      }
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

/**
 * NEW ROOT STATS ------------------------------------------------------------------------------------------
 */

export class PatientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllv2(
    params: {
      /**  */
      page?: number
      /**  */
      limit?: number
      /**  */
      older?: string
      /**  */
      sortBy?: string
      /**  */
      search: string
      /**  */
      sex: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/v2'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
        params,
      )

      configs.params = {
        limit: params['limit'],
        skip: params['skip'],
        sortBy: params['sortBy'],
        sex: params['sex'],
        search: params['search'],
      }
      let data = null
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PatientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdatePatientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateReferringDoctor(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdatePatientReferringDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}/referring-doctor'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class DoctorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Doctor> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class DirectoryService {
  /**
   *
   */
  static autocompleteByName(
    params: {
      /**  */
      name: string
      limit?: number
      page?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RppsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/directory/autocomplete/name'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { name: params['name'] }
      if (params['limit']) {
        configs.params['limit'] = params['limit']
      }
      if (params['page']) {
        configs.params['page'] = params['page']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static autocompleteByRpps(
    params: {
      /**  */
      rppsNumber: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SavoirFaireDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/directory/autocomplete/rpps'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { rppsNumber: params['rppsNumber'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class WaitingRoomService {
  /**
   *
   */
  static createExamRoom(
    params: {
      /** requestBody */
      body?: CreateWaitingRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<WaitingRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findPatients(
    params: {
      /**  */
      siteId: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<object[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { siteId: params['siteId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePatient(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findWaitingRoom(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static callPatient(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/call'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static uncallPatient(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/uncall'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static resetVisits(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/reset-visits'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class RoomService {
  /**
   *
   */
  static createExamRoom(
    params: {
      /** requestBody */
      body?: CreateExamRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllRooms(
    options: IRequestOptions = {},
  ): Promise<RoomWithTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/all-rooms'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllExamRoom(options: IRequestOptions = {}): Promise<ExamRoom[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/all-rooms'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneExamRoom(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateExamRoom(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateExamRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateReception(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateReceptionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/reception/{id}'
      url = url.replace('{id}', params.id + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateWaitingRoom(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateWaitingRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/waiting/{id}'
      url = url.replace('{id}', params.id + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static removeReception(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/reception'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static removeWaitingRooms(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/waiting'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static updateDoctorOffice(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removeExamRoom(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAvailableExams(
    params: {
      /**  */
      id: number
      /**  */
      doctorId: number
      /**  */
      examTypes: any | null[]
      /**  */
      bodyParts: any | null[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Exam[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}/available-exams'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        doctorId: params['doctorId'],
        examTypes: params['examTypes'],
        bodyParts: params['bodyParts'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createPrepRoom(
    params: {
      /** requestBody */
      body?: CreatePrepRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createReception(
    params: {
      /** requestBody */
      body?: CreateReceptionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/reception'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createWaitingRoom(
    params: {
      /** requestBody */
      body?: CreateWaitingRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/waiting'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static createDoctorOffice(
    params: {
      /** requestBody */
      body?: CreateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/office'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createDoctorOffice(
    params: {
      /** requestBody */
      body?: CreateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/office'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static findAllPrepRoom(options: IRequestOptions = {}): Promise<PrepRoom[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOnePrepRoom(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePrepRoom(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdatePrepRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removePrepRoom(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep/'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removeDoctorOffice(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + '/room/office/'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class EquipmentService {
  /**
   *
   */
  static createMedicalEquipment(
    params: {
      /** requestBody */
      body?: CreateMedicalEquipmentDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MedicalEquipment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/medical-equipment'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllMedicalEquipment(
    options: IRequestOptions = {},
  ): Promise<MedicalEquipment[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/medical-equipment'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneMedicalEquipment(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MedicalEquipment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/medical-equipment/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateMedicalEquipment(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateMedicalEquipmentDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/medical-equipment/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removeMedicalEquipment(
    ids: number | number[],
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/medical-equipment/'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      const data = ids

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createItEquipment(
    params: {
      /** requestBody */
      body?: CreateItEquipmentDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ItEquipment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/it-equipment'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllItEquipment(
    options: IRequestOptions = {},
  ): Promise<ItEquipment[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/it-equipment'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneItEquipment(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ItEquipment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/it-equipment/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateItEquipment(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateItEquipmentDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/it-equipment/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removeItEquipment(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/equipment/it-equipment'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      const data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
export class SiteService {
  /**
   *
   */
  static findAll(
    params: {
      clientId?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/v2'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { clientId: params['clientId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSiteDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateSiteDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    body: number | number[],
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      const data = body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllTypes(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/types'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      const data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class GedService {
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/download'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static uploadFile(
    params: {
      /**  */
      id: number
      /**  */
      type: string
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<GedFileDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/upload'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )
      configs.params = { type: params['type'] }
      let data = null
      data = new FormData()
      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class LogService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
      /**  */
      startDate: string
      /**  */
      endDate: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/log'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
        startDate: params['startDate'],
        endDate: params['endDate'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class UserService {
  /**
   *
   */
  static getPhoneVerificationCheck(
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static sendPhoneVerification(
    params: {
      /** requestBody */
      body?: CreatePhoneAuthMethod
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static phoneVerify(
    params: {
      /** requestBody */
      body?: PhoneVerifyRequest
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/verify'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findMyInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class AuthService {
  /**
   *
   */
  static getCaptcha(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/login'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static loginCps(options: IRequestOptions = {}): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/cps/login'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/logout'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static isValidFirstConnection(
    params: {
      /**  */
      accessionNumber: string
      /**  */
      birthDate: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        accessionNumber: params['accessionNumber'],
        birthDate: params['birthDate'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createUserFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterPatientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createDoctorFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/doctor/first-connection'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createLocalUserFromEmail(
    params: {
      /** requestBody */
      body?: LocalUserRegisterFromMailDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/verify-mail'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static forgottenPassword(
    params: {
      /**  */
      email: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/forgotten-password'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { email: params['email'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: LocalUserResetPasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/reset-password'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: LocalUserUpdatePasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/update-password'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateEmail(
    params: {
      /** requestBody */
      body?: LocalUserForgetPasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/update-email'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static requestToken(key: string): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/auth-key'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = { key: key }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class MemberService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      search?: string
      /**  */
      limit?: number
      /**  */
      skip?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        search: params['search'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateMemberDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateMemberDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Member> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class RoleService {
  /**
   *
   */
  static findAllRoles(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/role'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createRole(
    params: {
      /** requestBody */
      body?: CreateRoleDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/role'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneRole(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/role/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateRole(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateRoleDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Role> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/role/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static deleteRole(
    params: {
      /**  */
      ids: number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/role'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.ids

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ClientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateClientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class SuperAdminService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSuperAdminDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SuperAdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SuperAdmin> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateSuperAdminDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SuperAdmin> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ReceptionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateReceptionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Reception> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateReceptionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class OfficeService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Office> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ExamService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateExamDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      search?: string
      /**  */
      limit?: number
      /**  */
      skip?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        search: params['search'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllTypes(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createType(
    params: {
      /** requestBody */
      body?: CreateExamTypeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateExamDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number | number[]
    },
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneType(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateType(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateExamTypeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removeType(
    params: {
      /**  */
      id: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + '/exam/type'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      const data = params.id

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllInstructions(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + '/exam/instruction'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      axios(configs, resolve, reject)
    })
  }
}

export class QuestionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateQuestionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<QuestionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/questions'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      let data = params.body
      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      examId?: number | null
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/questions'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        examId: params['examId'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateQuestionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/questions/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      ids: number | number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const { ids: id } = params
      const ids = Array.isArray(id) ? id : [id]
      const url = basePath + '/questions'
      const q = qs.stringify(ids)

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${url}?${q}`,
        options,
      )
      configs.axios(configs, resolve, reject)
    })
  }
}

export interface LockPassword {
  /**  */
  id: number

  /**  */
  password: string

  /**  */
  account: Account

  /**  */
  accountId: number

  /**  */
  createdAt: Date

  /**  */
  expiredAt: Date
}

export interface LocalAuthMethod {
  /**  */
  lockoutPeriod?: LockoutPeriodEnum

  /**  */
  email: string

  /**  */
  password: string

  /**  */
  verificationUUID?: string

  /**  */
  resetPasswordUUID?: string

  /**  */
  failedAttempt?: number

  /**  */
  failedAttemptTime?: Date

  /**  */
  lockPasswords?: LockPassword[]
}

export interface CpsAuthMethod {
  /**  */
  disable: boolean

  /**  */
  rppsNumber: string

  /**  */
  firstConnection: boolean
}

export interface PhoneAuthMethod {
  /**  */
  phoneNumber: string

  /**  */
  phoneVerificationCode?: string

  /**  */
  isVerified: boolean
}

export interface AuthMethod {
  /**  */
  local: LocalAuthMethod

  /**  */
  cps?: CpsAuthMethod

  /**  */
  phone?: PhoneAuthMethod
}

export interface Reception extends Room {
  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface WaitingRoom extends Room {
  /**  */
  reception: Reception

  /**  */
  receptionId: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  called: Visit[]

  /**  */
  capacity: number

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface PrepRoom extends Room {
  /**  */
  status: EnumPrepRoomStatus

  /**  */
  isActive: boolean

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface ExamType {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  site: Site

  /**  */
  siteId: number

  /** */
  exams: { id: number }[]
}

export interface Exam {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: ModalityEnum

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: BodyPartEnum

  /**  */
  ageRange: AgeRangeEnum

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  wc: boolean

  /**  */
  id: number

  /**  */
  type: ExamType

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  instructionsList: Instruction[]

  /**  */
  isAutoPickupEnabled: boolean
}

export interface Room {
  id: integer
  label: string
  siteId: number
}

export interface RoomWithTypeDto extends Room {
  /** */
  type: RoomType
}

export interface ExamRoom extends Room {
  /**  */
  shared: boolean

  /**  */
  xrayCompatible: boolean

  /**  */
  mutualized: boolean

  /**  */
  emergency: boolean

  /**  */
  color: string

  /**  */
  active: boolean

  /**  */
  waitingRoom: WaitingRoom

  /**  */
  waitingRoomId: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  medicalEquipmentId: number

  /**  */
  openingTime: Date

  /**  */
  reopeningTime: Date

  /**  */
  breakTime: Date

  /**  */
  closingTime: Date

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  exams: Exam[]

  /**  */
  roomType: number

  /**  */
  processTime: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /**  */
  isAvailable: boolean

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface MedicalEquipment {
  /**  */
  id: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  type: EnumMedicalEquipmentType

  /**  */
  examRoom: ExamRoom

  /**  */
  ExamRoomId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  model?: string

  /**  */
  manufacturer: string

  /**  */
  commissioningDate: Date

  /**  */
  dose: boolean

  /**  */
  shared: boolean

  /**  */
  sharder: boolean

  /**  */
  active: boolean

  /**  */
  digital: boolean

  /**  */
  magnetPower: MagnetPowerEnum

  /** */
  consoleConstructor?: ConsoleConstructorEnum
}

export interface ItEquipment {
  /**  */
  site: Site

  /**  */
  SiteId: number

  /**  */
  type: string

  /**  */
  ip: string

  /**  */
  mac: string

  /**  */
  port: number

  /**  */
  infos: string

  /**  */
  id: number

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface Office {
  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface Site {
  /**  */
  primary: boolean

  /**  */
  externalId: string

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  address: string

  /** Describe the prefered engine for AWS Polly. Engine define speech's type. */
  preferenceSiteEngine: PollyEngineEnum

  /** For a site, describe the prefered language. Store a Language Code for AWS Poly and remember languages available depends of engine type. You can find the languages supported here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/SupportedLanguage.html  */
  preferenceSiteLanguage: string

  /** For a site, describe the prefered voice. Store a VoiceID for AWS Poly and remember voices available depends of engine type and language. You can find the Voices list here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/voicelist.html  */
  preferenceSiteVoice: string

  /**  */
  id: number

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  geocode: string

  /**  */
  capacity: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  itEquipments: ItEquipment[]

  /**  */
  examRooms: ExamRoom[]

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  offices: Office[]

  /**  */
  receptions: Reception[]

  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  exams: Exam[]

  /**  */
  examTypes: ExamType[]
}

export interface Member {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: MemberRoleEnum

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  sites: Site[]
}

export interface SecurityPreferences {
  /**  */
  failureNumber: FailureNumberEnum

  /**  */
  lockoutPeriod: LockoutPeriodEnum

  /**  */
  inactivityTimeout: number

  /**  */
  twoFAMandatory: boolean

  /**  */
  twoFAMethod: TwoFAMethodEnum

  /**  */
  passwordChangesMandatory: boolean

  /**  */
  passwordChangesPeriod: number

  /**  */
  samePasswordAllowed: boolean
}

export interface Viewer {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  brand: string

  /**  */
  nuclear: boolean

  /**  */
  measurement: boolean

  /**  */
  dicom: boolean

  /**  */
  CE: boolean

  /**  */
  gsps: boolean

  /**  */
  sr: boolean
}

export interface ViewerPreferences {
  /**  */
  patientViewer?: Viewer

  /**  */
  patientViewerId: number

  /**  */
  doctorViewer?: Viewer

  /**  */
  doctorViewerId: number

  /**  */
  radioViewer?: Viewer

  /**  */
  radioViewerId: number

  /**  */
  tomoViewer?: Viewer

  /**  */
  tomoViewerId: number

  /**  */
  nuclearViewer?: Viewer

  /**  */
  nuclearViewerId: number
}

export interface SharingPreferences {
  /**  */
  maxPermission: SharingPermissionEnum

  /**  */
  permission: SharingPermissionEnum

  /**  */
  ownershipAccept: boolean

  /**  */
  expiration: number
}

export interface ClientPreferences {
  /**  */
  security: SecurityPreferences

  /**  */
  viewers: ViewerPreferences

  /**  */
  sharing: SharingPreferences
}

export interface Client {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  postalCode: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  id: number

  /**  */
  fax: string

  /**  */
  externalId: string

  /**  */
  logo: string

  /**  */
  sites: Site[]

  /**  */
  members: Member[]

  /**  */
  preferences: ClientPreferences
}

export interface ExternalId {
  /**  */
  id: number

  /**  */
  value: string

  /**  */
  label: string

  /**  */
  description: string

  /**  */
  source: string

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  clientId: number

  /**  */
  client: Client
}

export interface DoctorTable {}

export interface DisplayPreferences {
  /**  */
  home: DoctorTable
}

export interface DoctorPreferences {
  /**  */
  display: DisplayPreferences
}

export interface Doctor extends Room {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  preferences: DoctorPreferences

  /**  */
  exams: Exam[]
}

export interface GedFile {
  /**  */
  id: number

  /**  */
  type: EnumGedFileType

  /**  */
  relatedEntity: number

  /**  */
  filename: string

  /**  */
  mimeType: string
}

export interface Consent {
  /**  */
  status: DocumentStatusEnum

  /**  */
  id: number

  /**  */
  file: GedFile
}

export interface PatientPreferences {
  /**  */
  display: DisplayPreferences
}

export interface Patient {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  referringDoctor: Doctor

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  visits: Visit[]

  /**  */
  studies: MedicalOrder[]

  /**  */
  preferences: PatientPreferences
}

export interface TextToSpeech {
  /** A mp3 file is associate with a waiting room */
  ttsURI: string

  /** Defines the availability of mp3. An mp3 is available for 72 hours in the S3 bucket */
  expireAt: Date

  /** Deleted date manages by typeORM on softdelete function */
  deleteAt: Date

  /**  */
  id: number

  /**  */
  createdAt: Date

  /**  */
  reception: object

  /**  */
  receptionId?: number

  /**  */
  examRoom: object

  /**  */
  examRoomId?: number

  /**  */
  doctorOffice: object

  /**  */
  doctorOfficeId?: number

  /**  */
  prepRoom: object

  /**  */
  prepRoomId?: number

  /**  */
  roomType: EnumTextToSpeechRoomType

  /**  */
  visit: Visit

  /**  */
  visitId: number
}

export interface Visit {
  /**  */
  id: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  visitNumber: string

  /**  */
  studies: MedicalOrder[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  admissionDate: Date

  /**  */
  dischargedDate: Date

  /**  */
  ticketNumber: string

  /**  */
  waitingRoomStatus: EnumVisitWaitingRoomStatus

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  hospitalService: string

  /**  */
  admitSource: EnumVisitAdmitSource

  /**  */
  emergency?: boolean

  /**  */
  dementia?: boolean

  /**  */
  visuallyImpaired?: boolean

  /**  */
  blind?: boolean

  /**  */
  crutches?: boolean

  /**  */
  stretcher?: boolean

  /**  */
  wheelchair?: boolean

  /**  */
  deaf?: boolean

  /**  */
  chatRequest?: boolean

  /**  */
  sawDoctor?: boolean

  /**  */
  speeches: TextToSpeech[]

  /**  */
  cancelReason?: string

  /**  */
  accompanying: number
}

export interface Prescription {
  /**  */
  status: PrescriptionStatusEnum

  /**  */
  id: number

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface Report {
  /**  */
  status: ReportStatusEnum

  /**  */
  id: number

  /**  */
  file: GedFile

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface Manufacturer {
  /**  */
  id: number

  /**  */
  name: string

  /**  */
  modelName: string
}

export interface Instance {
  /**  */
  uid: string

  /**  */
  serie: Serie

  /**  */
  number: number

  /**  */
  uiSopClassUid: string

  /**  */
  studyInstanceUid: string

  /**  */
  createdAt: Date

  /**  */
  contentDate: Date
}

export interface Serie {
  /**  */
  uid: string

  /**  */
  radiologyStudy: RadiologyStudy

  /**  */
  number: number

  /**  */
  laterality: string

  /**  */
  createdDate: Date

  /**  */
  time: Date

  /**  */
  modality: string

  /**  */
  institutionName: string

  /**  */
  description: string

  /**  */
  performingPhysician: string

  /**  */
  manufacturer: Manufacturer

  /**  */
  instances: Instance[]
}

export interface RadiologyStudy {
  /**  */
  uid: string

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  description: string

  /**  */
  imagesNumber: number

  /**  */
  series: Serie[]

  /**  */
  admittingDiagnoseDescription: string

  /**  */
  time: Date
}

export interface MedicalOrder {
  /**  */
  status: MedicalOrderStatusEnum

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  attendingDoctor: Doctor

  /**  */
  referringDoctor: Doctor

  /**  */
  consultingDoctor: Doctor

  /**  */
  prescription: Prescription

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  studyInstanceUID: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: Shares[]

  /**  */
  radiologyStudy: RadiologyStudy

  patientTransport?: PatientTransportDto

  patientTransportId?: number
}

export interface Shares {
  /**  */
  permission?: SharingPermissionEnum

  /**  */
  id: number

  /**  */
  startDate: Date

  /**  */
  endDate?: Date

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  medicalOrderId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  accessCode: string
}

export interface Account {
  /**  */
  role: AccountRoleEnum

  /**  */
  id: number

  /**  */
  authMethod: AuthMethod

  /**  */
  locked?: boolean

  /**  */
  shares: Shares[]
}

export interface DoctorDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  preferences: DoctorPreferences

  /**  */
  exams: Exam[]
}

export interface PatientDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  referringDoctor: DoctorDto

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  visits: Visit[]

  /**  */
  studies: MedicalOrder[]

  /**  */
  preferences: PatientPreferences
}

export interface UpdatePatientDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  ssn?: string

  /**  */
  surName?: string

  /**  */
  externalIds?: ExternalId[]

  /**  */
  birthDate?: Date

  /**  */
  placeOfBirth?: string

  /**  */
  legalPerson?: string

  /**  */
  maritalStatus?: string

  /**  */
  mergedDate?: Date

  /**  */
  mergedPatient?: Patient

  /**  */
  referringDoctor?: Doctor

  /**  */
  blackListedDoctors?: Doctor[]

  /**  */
  ALD?: boolean

  /**  */
  CSS?: boolean

  /**  */
  CMU?: boolean

  /**  */
  consent?: Consent

  /**  */
  visits?: Visit[]

  /**  */
  studies?: MedicalOrder[]

  /**  */
  preferences?: PatientPreferences
}

export interface UpdatePatientReferringDoctorDto {
  /**  */
  shareOld: boolean

  /**  */
  shareNew: boolean

  /**  */
  rppsNumber: string
}

export interface CreateDoctorDto {
  /**  */
  accountId: number

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  exams: Exam[]
}

export interface UpdateDoctorDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  rppsNumber?: string

  /**  */
  title?: string

  /**  */
  category?: string

  /**  */
  skill?: string

  /**  */
  profession?: string

  /**  */
  preferences?: DoctorPreferences

  /**  */
  exams?: { id: number }[]
}

export interface RppsDto {
  /**  */
  id: number

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  profession: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  skillType: string

  /**  */
  streetAddress: string

  /**  */
  zipCode: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  fax: string

  /**  */
  email: string

  /**  */
  createAt: Date

  /**  */
  updateAt: Date

  /**  */
  deletedAt: Date
}

export interface SavoirFaireDto {
  /**  */
  id: number

  /**  */
  rppsNumber: string

  /**  */
  lastName: string

  /**  */
  firstName: string

  /**  */
  categoryLabel: string

  /**  */
  categoryCode: string

  /**  */
  professionLabel: string

  /**  */
  professionCode: string

  /**  */
  skillLabel: string

  /**  */
  skillCode: string

  /**  */
  skillTypeLabel: string

  /**  */
  skillTypeCode: string

  /**  */
  createAt: number

  /**  */
  updateAt: number

  /**  */
  deletedAt: number
}

export interface CreateWaitingRoomDto {
  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  receptionId: number

  /**  */
  capacity: number
}

export interface UpdateWaitingRoomDto {
  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  receptionId?: number

  /**  */
  capacity?: number
}

export interface CreateExamRoomDto {
  /**  */
  shared: boolean

  /**  */
  xrayCompatible: boolean

  /**  */
  mutualized: boolean

  /**  */
  emergency: boolean

  /**  */
  color: string

  /**  */
  active: boolean

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  waitingRoomId: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  medicalEquipmentId: number

  /**  */
  openingTime: Date

  /**  */
  reopeningTime: Date

  /**  */
  breakTime: Date

  /**  */
  closingTime: Date

  /**  */
  exams: Exam[]

  /**  */
  roomType: number

  /**  */
  processTime: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /**  */
  isAvailable: boolean
}

export interface UpdateExamRoomDto {
  /**  */
  shared?: boolean

  /**  */
  xrayCompatible?: boolean

  /**  */
  mutualized?: boolean

  /**  */
  emergency?: boolean

  /**  */
  color?: string

  /**  */
  active?: boolean

  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  waitingRoomId?: number

  /**  */
  medicalEquipments?: MedicalEquipment[]

  /**  */
  medicalEquipmentId?: number

  /**  */
  openingTime?: Date

  /**  */
  reopeningTime?: Date

  /**  */
  breakTime?: Date

  /**  */
  closingTime?: Date

  /**  */
  prepRooms?: PrepRoom[]

  /**  */
  exams?: Exam[]

  /**  */
  roomType?: number

  /**  */
  processTime?: number

  /**  */
  isWheelchairCompatible?: boolean

  /**  */
  isStretcherCompatible?: boolean

  /**  */
  isAvailable?: boolean
}

export interface CreatePrepRoomDto {
  /**  */
  status: EnumCreatePrepRoomDtoStatus

  /**  */
  isActive: boolean

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  examRoomId: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean
}

export interface Instruction {
  /** */
  label: string

  /** */
  imageUrl: string
}

export interface UpdatePrepRoomDto {
  /**  */
  status?: EnumUpdatePrepRoomDtoStatus

  /**  */
  isActive?: boolean

  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  examRoom?: ExamRoom

  /**  */
  examRoomId?: number

  /**  */
  isWheelchairCompatible?: boolean

  /**  */
  isStretcherCompatible?: boolean
}

export interface MedicalEquipmentDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  examRoom?: ExamRoom
  /**  */
  label?: string

  /**  */
  model?: string

  /**  */
  manufacturer?: string

  /**  */
  commissioningDate?: Date

  /**  */
  dose: boolean

  /**  */
  sharder: boolean

  /**  */
  active: boolean
}
export interface CreateMedicalEquipmentDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  examRoom?: ExamRoom
  /**  */
  label?: string

  /**  */
  model?: string

  /**  */
  manufacturer?: string

  /**  */
  commissioningDate?: Date

  /**  */
  dose: boolean

  /**  */
  sharder: boolean

  /**  */
  active: boolean

  /**  */
  type: ModalityEnum
}

export interface UpdateMedicalEquipmentDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  examRoom?: ExamRoom
  /**  */
  label?: string

  /**  */
  model?: string

  /**  */
  manufacturer?: string

  /**  */
  commissioningDate?: Date

  /**  */
  dose?: boolean

  /**  */
  sharder?: boolean

  /**  */
  active?: boolean

  /**  */
  digital?: boolean

  /**  */
  type?: ModalityEnum

  /** */
  magnetPower?: MagnetPowerEnum

  /** */
  consoleConstructor?: ConsoleConstructorEnum
}

export interface ItEquipmentDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  type?: string

  /**  */
  ip?: string

  /**  */
  mac?: string

  /**  */
  port?: string

  /**  */
  info?: string
}
export interface CreateItEquipmentDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  type?: string

  /**  */
  ip?: string

  /**  */
  mac?: string

  /**  */
  port?: number

  /**  */
  info?: string
}

export interface UpdateItEquipmentDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  type?: string

  /**  */
  ip?: string

  /**  */
  mac?: string

  /**  */
  port?: number

  /**  */
  info?: string
}

export interface SiteDto {
  /**  */
  primary: boolean

  /**  */
  externalId: string

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  streetAddress?: string

  /**  */
  postalCode?: number

  /**  */
  city?: string

  /**  */
  country?: string

  /** Describe the prefered engine for AWS Polly. Engine define speech's type. */
  preferenceSiteEngine: PollyEngineEnum

  /** For a site, describe the prefered language. Store a Language Code for AWS Poly and remember languages available depends of engine type. You can find the languages supported here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/SupportedLanguage.html  */
  preferenceSiteLanguage: string

  /** For a site, describe the prefered voice. Store a VoiceID for AWS Poly and remember voices available depends of engine type and language. You can find the Voices list here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/voicelist.html  */
  preferenceSiteVoice: string

  /**  */
  id: number

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  geocode: string

  /**  */
  capacity: number

  /**  */
  siteTypeId: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  itEquipments: ItEquipment[]

  /**  */
  examRooms: ExamRoom[]

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  offices: Office[]

  /**  */
  receptions: Reception[]

  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  exams: Exam[]

  /**  */
  examTypes: ExamType[]

  /**  */
  siteType: SiteTypeDto
}

export interface CreateSiteDto {
  /**  */
  primary: boolean

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  streetAddress?: string

  /**  */
  postalCode?: number

  /**  */
  city?: string

  /**  */
  country?: string

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber?: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  capacity: number

  /**  */
  siteTypeId?: number
}

export interface UpdateSiteDto {
  /**  */
  primary?: boolean

  /**  */
  externalId?: string

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  streetAddress?: string

  /**  */
  postalCode?: number

  /**  */
  city?: string

  /**  */
  country?: string

  /** Describe the prefered engine for AWS Polly. Engine define speech's type. */
  preferenceSiteEngine?: PollyEngineEnum

  /** For a site, describe the prefered language. Store a Language Code for AWS Poly and remember languages available depends of engine type. You can find the languages supported here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/SupportedLanguage.html  */
  preferenceSiteLanguage?: string

  /** For a site, describe the prefered voice. Store a VoiceID for AWS Poly and remember voices available depends of engine type and language. You can find the Voices list here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/voicelist.html  */
  preferenceSiteVoice?: string

  /**  */
  id?: number

  /**  */
  clientId?: number

  /**  */
  client?: Client

  /**  */
  finessNumber?: string

  /**  */
  finessStructNumber?: string

  /**  */
  status?: string

  /**  */
  email?: string

  /**  */
  phoneNumber?: string

  /**  */
  fax?: string

  /**  */
  geocode?: string

  /**  */
  capacity?: number

  /**  */
  siteTypeId?: number

  /**  */
  medicalEquipments?: MedicalEquipment[]

  /**  */
  itEquipments?: ItEquipment[]

  /**  */
  examRooms?: ExamRoom[]

  /**  */
  prepRooms?: PrepRoom[]

  /**  */
  offices?: Office[]

  /**  */
  receptions?: Reception[]

  /**  */
  waitingRooms?: WaitingRoom[]

  /**  */
  exams?: Exam[]

  /**  */
  examTypes?: ExamType[]
}

export interface GedFileDto {
  /**  */
  id: number

  /**  */
  type: EnumGedFileDtoType

  /**  */
  relatedEntity: number

  /**  */
  filename: string

  /**  */
  mimeType: string
}

export interface CreatePhoneAuthMethod {
  /**  */
  phoneNumber: string
}

export interface PhoneVerifyRequest {
  /**  */
  verificationCode: string
}

export interface LoginDto {
  /**  */
  email: string

  /**  */
  password: string

  /**  */
  captcha: string
}

export interface AccountDto {
  /**  */
  role: AccountRoleEnum

  /**  */
  email: string

  /**  */
  client: number

  /**  */
  phone: string

  /**  */
  security: SecurityPreferences

  /**  */
  id: number

  /**  */
  locked?: boolean
}

export interface LoginResDto {
  /**  */
  token?: string

  /**  */
  user: AccountDto
}

export interface LocalUserRegisterPatientDto {
  /**  */
  email?: string

  /**  */
  password?: string

  /**  */
  accessionNumber: string

  /**  */
  birthDate: string
}

export interface LocalUserRegisterDoctorDto {
  /**  */
  email: string

  /**  */
  password?: string

  /**  */
  rppsNumber: string
}

export interface LocalUserRegisterFromMailDto {
  /**  */
  userId: string

  /**  */
  verificationUUID: string
}

export interface LocalUserResetPasswordDto {
  /**  */
  newPassword: string

  /**  */
  resetPasswordUUID: string
}

export interface LocalUserUpdatePasswordDto {
  /**  */
  newPassword: string
}

export interface LocalUserForgetPasswordDto {
  /**  */
  email: string
}

export interface MemberDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: MemberRoleEnum

  /**  */
  clientId: number

  /**  */
  client: Client
}
export interface CreateMemberDto {
  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  email: string

  /**  */
  password: string

  /**  */
  role: MemberRoleEnum
}

export interface UpdateMemberDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  password: string

  /**  */
  role?: MemberRoleEnum

  /**  */
  clientId?: number

  /**  */
  client?: Client
}

export interface RoleDto {
  /**  */
  id: number

  /**  */
  name: string

  /**  */
  description: string

  /**  */
  permissions: PermissionDto[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  members: ({ id: number } & Partial<MemberDto>)[]
}

export class PermissionDto {
  permission: AccessEnum
}

export interface CreateRoleDto {
  /**  */
  name: string

  /**  */
  description: string

  /**  */
  permissions: AccessEnum[]

  /**  */
  siteId: number

  /** */
  members?: { id: number }[]
}

export interface UpdateRoleDto {
  /**  */
  name?: string

  /**  */
  description?: string

  /**  */
  permissions?: AccessEnum[]

  /**  */
  siteId?: number

  /** */
  members?: { id: number }[]
}

export interface ChoiceDto {
  /**  */
  id: number

  /**  */
  text: string
}

export interface QuestionDto {
  /**  */
  id: number

  /**  */
  label: string

  /**  */
  text: string

  /**  */
  questionType: string

  /**  */
  siteId: number

  /**  */
  exams: ({ id: number } & Partial<ExamDto>)[]

  /** */
  choices: ChoiceDto[]
}

export interface CreateQuestionDto {
  /**  */
  label: string

  /**  */
  question: string

  /**  */
  questionType: string

  /**  */
  siteId: number

  /** */
  exams?: { id: number }[]
}

export interface UpdateQuestionDto {
  /**  */
  label?: string

  /**  */
  question?: string

  /**  */
  questionType?: string

  /**  */
  siteId?: number

  /** */
  exams?: { id: number }[]
}

export interface ClientDto {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  postalCode: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  id: number

  /**  */
  fax: string

  /**  */
  externalId: string

  /**  */
  logo: string

  /**  */
  sites: Site[]

  /**  */
  members: Member[]

  /**  */
  preferences: ClientPreferences
}

export interface CreateClientDto {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  postalCode: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string
}

export interface UpdateClientDto {
  /**  */
  name?: string

  /**  */
  status?: number

  /**  */
  type?: ClientTypeEnum

  /**  */
  sirenNumber?: string

  /**  */
  address?: string

  /**  */
  postalCode?: string

  /**  */
  email?: string

  /**  */
  phoneNumber?: string

  /**  */
  id?: number

  /**  */
  fax?: string

  /**  */
  logo?: string

  /**  */
  sites?: Site[]

  /**  */
  members?: Member[]

  /**  */
  preferences?: ClientPreferences
}

export interface SuperAdmin {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string
}
export interface LogDto {
  /**  */
  id?: number

  /**  */
  entityId?: number

  /**  */
  role?: string

  /**  */
  action?: any

  /**  */
  createdAt?: Date
}

export interface CreateSuperAdminDto {
  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  password: string
}

export interface SuperAdminDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string
}

export interface UpdateSuperAdminDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string
}

export interface CreateReceptionDto {
  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface UpdateReceptionDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  waitingRooms?: WaitingRoom[]
}

export interface CreateOfficeDto {
  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface UpdateOfficeDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string
}

export interface CreateExamDto {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: ModalityEnum

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: BodyPartEnum

  /**  */
  ageRange: AgeRangeEnum

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  wc: boolean

  /**  */
  siteId: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  instructionsList?: string[]
}

export interface ExamDto {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: ModalityEnum

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: BodyPartEnum

  /**  */
  ageRange: AgeRangeEnum

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  id: number

  /**  */
  type: ExamType

  /**  */
  examTypes: ExamType[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  examRooms: ExamRoom[]
}

export interface UpdateExamDto {
  /**  */
  code?: string

  /**  */
  label?: string

  /**  */
  modality?: ModalityEnum

  /**  */
  procedureTime?: number

  /**  */
  sex?: SexEnum

  /**  */
  bodyPart?: BodyPartEnum

  /**  */
  ageRange?: AgeRangeEnum

  /**  */
  instructionsList?: string[]

  /**  */
  dose?: boolean

  /**  */
  contrast?: boolean

  /**  */
  shower?: boolean

  /**  */
  fasted?: boolean

  /**  */
  medication?: boolean

  /**  */
  wc: boolean

  /**  */
  id?: number

  /**  */
  type?: ExamType

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  examRooms?: ExamRoom[]
}

export interface CreateExamTypeDto {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  site: Site

  /**  */
  siteId: number

  /** */
  exams?: { id: number }[]
}

export interface ExamTypeDto {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  site: Site

  /**  */
  siteId: number
}

export interface UpdateExamTypeDto {
  /**  */
  id?: number

  /**  */
  code?: string

  /**  */
  label?: string

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /** */
  exams?: { id: number }[]
}

export interface RoomDto {
  /**  */
  modality?: ModalityEnum

  /**  */
  sex?: SexEnum

  /**  */
  ageRange?: AgeRangeEnum

  /**  */
  id?: number

  /**  */
  code?: string

  /**  */
  label?: string
}

export interface PaginatedQueryBase {
  /** */
  skip: number

  /** */
  limit: number
}

export interface FindMembersQuery extends PaginatedQueryBase {
  /** */
  search: string
}

export interface FindExamsQuery extends PaginatedQueryBase {
  /** */
  search: string
}

export interface SiteTypeDto {
  /** */
  id: number

  /** */
  label: string
}

export interface Instruction {
  label: string
  imageUrl: string
}

export interface CreateAmbulanceCompanyDto {
  name: string

  address?: string

  zipCode?: number

  city?: string

  siret?: string

  email?: string

  contact: string

  siteIds?: number[]

  siteCode?: string

  transportType?: string
}

export interface UpdateAmbulanceCompanyDto {
  id?: number

  name?: string

  address?: string

  zipCode?: number

  city?: string

  siret?: string

  email?: string

  contact?: string

  siteIds?: number[]
}

export interface AmbulanceCompanyDto {
  id: number

  name: string

  address: string

  zipCode: number

  city: string

  siret: string

  email: string

  phone1: string

  phone2: string

  sites: SiteDto[]

  transportType?: string
}

export interface PaginatedQuery {
  page?: number | undefined

  limit?: number | undefined
}

export interface PatientTransportDto {
  id: number

  name: string

  phoneNumber: string

  plateNumber: string

  ambulanceCompany: AmbulanceCompanyDto

  ambulanceCompanyId?: number

  comments: string

  status: PatientTransportStatus

  transportType: string

  transportLinkType: string
}

export interface CreatePatientTransportDto {
  name: string

  phoneNumber: string

  companyId?: number | null

  plateNumber: string

  comments: string

  status: PatientTransportStatus

  transportType: string

  transportLinkType: string

  medicalOrderId: number
}

export interface UpdateAmbulanceDriverDto {
  name?: string

  phoneNumber?: string

  companyId?: number | null

  plateNumber?: string

  comments?: string

  status?: PatientTransportStatus

  transportType?: string

  transportLinkType?: string
}
export interface UpdateMedicalOrderDto {
  id?: number
  hl7MessageId?: string | null
  visitId?: number
  siteId?: number
  patientId?: number
  examRoomId?: number
  examId?: number
  status?: MedicalOrderStatusEnum
  plannedDate?: Date
  editedDate?: Date
  cancelDate?: Date
  description?: string
  studyInstanceUID?: string
  accessionNumber?: string
  service?: string
  attendingDoctorId?: number
  referringDoctorId?: number
  consultingDoctorId?: number
  attendingDoctorName?: string
  consultingDoctorName?: string
  referringDoctorName?: string
  protocolCode?: string | null
  isMultistepParent?: boolean
  dosePerKg?: number | null
  confirmedNmDose?: number
  acquisitionProtocols?: AcquisitionProtocol[]
  commentairesAquisitionProtocol?: string
  commentairesDose?: string
  patientTransportId?: number
}
export interface MedicalOrder {
  /**  */
  status: MedicalOrderStatusEnum

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  attendingDoctor: Doctor

  /**  */
  referringDoctor: Doctor

  /**  */
  consultingDoctor: Doctor

  /**  */
  prescription: Prescription

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  studyInstanceUID: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: Shares[]

  /**  */
  radiologyStudy: RadiologyStudy

  /** */
  resultConclusion?: string

  /** */
  pickupTime?: Date
}

export interface Visit {
  /**  */
  id: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  visitNumber: string

  /**  */
  studies: MedicalOrder[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  admissionDate: Date

  /**  */
  dischargedDate: Date

  /**  */
  ticketNumber: string

  /**  */
  waitingRoomStatus: EnumVisitWaitingRoomStatus

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  hospitalService: string

  /**  */
  admitSource: EnumVisitAdmitSource

  /**  */
  emergency?: boolean

  /**  */
  dementia?: boolean

  /**  */
  visuallyImpaired?: boolean

  /**  */
  blind?: boolean

  /**  */
  crutches?: boolean

  /**  */
  stretcher?: boolean

  /**  */
  wheelchair?: boolean

  /**  */
  deaf?: boolean

  /**  */
  chatRequest?: boolean

  /**  */
  sawDoctor?: boolean

  /**  */
  speeches: TextToSpeech[]

  /**  */
  cancelReason?: string

  /**  */
  accompanying: number
}

export interface Patient {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  referringDoctor: Doctor

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  visits: Visit[]

  /**  */
  studies: MedicalOrder[]

  /**  */
  preferences: PatientPreferences

  /** */
  hasConsent: boolean
}

export interface Doctor {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  preferences: DoctorPreferences

  /**  */
  exams: Exam[]
}

export interface Prescription {
  /**  */
  status: PrescriptionStatusEnum

  /**  */
  id: number

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface Consent {
  /**  */
  status: DocumentStatusEnum

  /**  */
  id: number

  /**  */
  file: GedFile
}
export interface Report {
  /**  */
  status: ReportStatusEnum

  /**  */
  id: number

  /**  */
  file: GedFile

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface RadiologyStudy {
  /**  */
  uid: string

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  description: string

  /**  */
  imagesNumber: number

  /**  */
  series: Serie[]

  /**  */
  admittingDiagnoseDescription: string

  /**  */
  time: Date
}

export interface Document {
  /** */
  id: number

  /** */
  fileId: number

  /** */
  medicalOrderId: number

  /** */
  fileType: FileTypeEnum

  /** */
  gedFile: GedFile

  /** */
  url: string
}

export interface DoctorPreferences {
  /**  */
  display: DisplayPreferences
}
export interface ExternalId {
  /**  */
  id: number

  /**  */
  value: string

  /**  */
  label: string

  /**  */
  description: string

  /**  */
  source: string

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  clientId: number

  /**  */
  client: Client
}

export enum PrescriptionStatusEnum {
  'validated' = 'validated',
  'in-progress' = 'in-progress',
  'refused' = 'refused',
}

export enum DocumentStatusEnum {
  'validated' = 'validated',
  'refused' = 'refused',
  'processing' = 'processing',
}

export enum ReportStatusEnum {
  'in-progress' = 'in-progress',
  'final' = 'final',
  'non-final' = 'non-final',
}

export enum EnumGedFileType {
  'prescription' = 'PRESCRIPTION',
  'report' = 'REPORT',
  'id' = 'ID',
  'letter' = 'LETTER',
  'previousExam' = 'PREVIOUS_EXAM',
  // 'consent' = 'CONSENT',
}

export enum MedicalOrderStatusEnum {
  'available' = 'available',
  'canceled' = 'canceled',
  'completed' = 'completed',
  'discontinued' = 'discontinued',
  'error' = 'error',
  'hold' = 'hold',
  'unspecified' = 'unspecified',
  'replaced' = 'replaced',
  'scheduled' = 'scheduled',
}

export enum AccountRoleEnum {
  'patient' = 'patient',
  'doctor' = 'doctor',
  'member' = 'member',
  'super-admin' = 'super-admin',
  'guest' = 'guest',
}

export enum LockoutPeriodEnum {
  'TWO_MINUTES' = 'TWO_MINUTES',
  'FIVE_MINUTES' = 'FIVE_MINUTES',
  'FIFTEEN_MINUTES' = 'FIFTEEN_MINUTES',
  'ONE_HOUR' = 'ONE_HOUR',
  'TWELVE_HOURS' = 'TWELVE_HOURS',
  'ONE_DAY' = 'ONE_DAY',
}

export enum SharingPermissionEnum {
  'view' = 'view',
  'view-and-report' = 'view-and-report',
  'export' = 'export',
  'owner' = 'owner',
}

export enum MedicalOrderStatusEnum {
  'available' = 'available',
  'canceled' = 'canceled',
  'completed' = 'completed',
  'discontinued' = 'discontinued',
  'error' = 'error',
  'hold' = 'hold',
  'unspecified' = 'unspecified',
  'replaced' = 'replaced',
  'scheduled' = 'scheduled',
}

export enum SexEnum {
  'A' = 'A',
  'F' = 'F',
  'M' = 'M',
  'N' = 'N',
  'O' = 'O',
  'U' = 'U',
}

export enum ClientTypeEnum {
  'public' = 'public',
  'private' = 'private',
  'mixed' = 'mixed',
}

export enum PollyEngineEnum {
  'standard' = 'standard',
  'neural' = 'neural',
}
export enum EnumPrepRoomStatus {
  'free' = 'free',
  'busy' = 'busy',
}
export enum ModalityEnum {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
  map = 'map',
}

export enum BodyPartEnum {
  'cerebral' = 'cerebral',
  'skull' = 'skull',
  'pelvis' = 'pelvis',
  'ankle' = 'ankle',
  'elbow' = 'elbow',
  'facial' = 'facial',
  'shoulder' = 'shoulder',
  'knee' = 'knee',
  'hip' = 'hip',
  'leg' = 'leg',
  'hand' = 'hand',
  'maxillary' = 'maxillary',
  'pelvimetry' = 'pelvimetry',
  'foot' = 'foot',
  'wrist' = 'wrist',
  'cervical-spine' = 'cervical-spine',
  'thoracic-spine' = 'thoracic-spine',
  'lumbar-spine' = 'lumbar-spine',
  'sinus' = 'sinus',
  'thoracic' = 'thoracic',
  'abdominal-pelvic' = 'abdominal-pelvic',
  'thoracic-abdominal-pelvic' = 'thoracic-abdominal-pelvic',
  'other' = 'other',
}

export enum AgeRangeEnum {
  'all' = 'all',
  'baby' = 'baby',
  'kid' = 'kid',
  'adult' = 'adult',
  'senior' = 'senior',
}

export enum InstructionEnum {
  'underwear-male' = 'underwear-male',
  'underwear-female' = 'underwear-female',
  'no-top' = 'no-top',
  'no-pants' = 'no-pants',
  'no-framed-bra' = 'no-framed-bra',
  'no-belt' = 'no-belt',
  'no-shoes-male' = 'no-shoes-male',
  'no-shoes-female' = 'no-shoes-female',
  'no-jewellery-head' = 'no-jewellery-head',
  'no-jewellery-body' = 'no-jewellery-body',
  'no-jewellery-hand' = 'no-jewellery-hand',
  'no-piercing' = 'no-piercing',
  'blouse' = 'blouse',
}
export enum EnumMedicalEquipmentType {
  'US' = 'US',
  'CT' = 'CT',
  'MR' = 'MR',
  'RCT' = 'RCT',
  'BDUS' = 'BDUS',
  'BMD' = 'BMD',
  'MG' = 'MG',
  'EOS' = 'EOS',
  'PT' = 'PT',
  'NM' = 'NM',
  'LINAC' = 'LINAC',
  'CP' = 'CP',
  'OT' = 'OT',
}
export enum MemberRoleEnum {
  'health-executive' = 'health-executive',
  'executive-director' = 'executive-director',
  'finance-director' = 'finance-director',
  'accountant' = 'accountant',
  'computer-technician' = 'computer-technician',
  'security' = 'security',
  'rssi' = 'rssi',
  'dsi' = 'dsi',
  'secretary' = 'secretary',
  'radiographer' = 'radiographer',
  'service-provider' = 'service-provider',
  'it-provider' = 'it-provider',
  'manager' = 'manager',
  'radiologist' = 'radiologist',
  'head-of-emergency' = 'head-of-emergency',
  'head-of-radiology' = 'head-of-radiology',
  'biomedical-engineer' = 'biomedical-engineer',
  'quality-manager' = 'quality-manager',
  'other' = 'other',
}

export enum FailureNumberEnum {
  'THREE' = 'THREE',
  'FIVE' = 'FIVE',
  'SEVEN' = 'SEVEN',
  'TEN' = 'TEN',
}

export enum TwoFAMethodEnum {
  'sms' = 'sms',
  'email' = 'email',
}

export enum DocumentStatusEnum {
  'validated' = 'validated',
  'refused' = 'refused',
  'processing' = 'processing',
}
export enum EnumGedFileType {
  'prescription' = 'prescription',
  'report' = 'report',
  'profile' = 'profile',
  'cover' = 'cover',
}
export enum EnumTextToSpeechRoomType {
  'PrepRoom' = 'PrepRoom',
  'ExamRoom' = 'ExamRoom',
  'DoctorOffice' = 'DoctorOffice',
  'WaitingRoom' = 'WaitingRoom',
  'Reception' = 'Reception',
}
export enum EnumVisitWaitingRoomStatus {
  'planned' = 'planned',
  'registered' = 'registered',
  'in-waiting-room' = 'in-waiting-room',
  'in-exam' = 'in-exam',
  'with-doctor' = 'with-doctor',
  'pending-result' = 'pending-result',
  'result-ok' = 'result-ok',
  'discharged' = 'discharged',
  'cancel' = 'cancel',
  'saw-doctor' = 'saw-doctor',
  'preparing_for_exam' = 'preparing_for_exam',
  'pending_reschedule' = 'pending_reschedule',
  'exam_canceled' = 'exam_canceled',
}
export enum EnumVisitAdmitSource {
  'unknown' = 'unknown',
  'physician-referral' = 'physician-referral',
  'clinic-referral' = 'clinic-referral',
  'hm0-referral' = 'hm0-referral',
  'transfer-from-' = 'transfer-from-',
  'emergency-room' = 'emergency-room',
  'concert' = 'concert',
  'information-not-available' = 'information-not-available',
}
export enum PrescriptionStatusEnum {
  'validated' = 'validated',
  'in-progress' = 'in-progress',
  'refused' = 'refused',
}

export enum ReportStatusEnum {
  'in-progress' = 'in-progress',
  'final' = 'final',
  'non-final' = 'non-final',
}
export enum EnumCreatePrepRoomDtoStatus {
  'free' = 'free',
  'busy' = 'busy',
}
export enum EnumUpdatePrepRoomDtoStatus {
  'free' = 'free',
  'busy' = 'busy',
}
export enum EnumGedFileDtoType {
  'prescription' = 'prescription',
  'report' = 'report',
  'profile' = 'profile',
  'cover' = 'cover',
}

export enum RoomType {
  PrepRoom = 'PrepRoom',
  ExamRoom = 'ExamRoom',
  DoctorOffice = 'DoctorOffice',
  WaitingRoom = 'WaitingRoom',
  Reception = 'Reception',
}

export enum ConsoleConstructorEnum {
  server = 'server',
  standalone = 'standalone',
}

export enum MagnetPowerEnum {
  ZERO_FIVE_T = '0.5T',
  ONE_T = '1T',
  ONE__FIVE_T = '1.5T',
  THREE_T = '3T',
  SEVEN_T = '7T',
  SEVEN_PLUS_T = '7T+',
}

export enum OncologyEnum {
  aria = 'aria',
  mosaiq = 'mosaiq',
}

export enum AccessEnum {
  WRM_VIEW_RECEPTIONS = 'WRM_VIEW_RECEPTIONS',
  WRM_VIEW_WAITIGN_ROOMS = 'WRM_VIEW_WAITIGN_ROOMS',
  WRM_VIEW_DOCTOR_OFFICES = 'WRM_VIEW_DOCTOR_OFFICES',
  WRM_VIEW_EXAM_ROOMS = 'WRM_VIEW_EXAM_ROOMS',
  WRM_CALL_TO_RECEPTION = 'WRM_CALL_TO_RECEPTION',
  WRM_CALL_TO_EXAM_ROOM = 'WRM_CALL_TO_EXAM_ROOM',
  WRM_CALL_TO_DOCTOR_OFFICE = 'WRM_CALL_TO_DOCTOR_OFFICE',
}

export enum QuestionTypeEnum {
  open = 'Open',
  close = 'Close',
  date = 'Date',
}

export enum PatientTransportType {
  ambulance = 'ambulance',
  taxi = 'taxi',
  other = 'other',
}

export enum PatientTransportStatus {
  Called = 'CALLED',
  NotCalled = 'NOT_CALLED',
}
