import { MemberService, CreateMemberDto, UpdateMemberDto } from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAll = createAsyncThunk(
  Actions.MEMBER_FIND_ALL,
  async (
    params: { search?: string; limit?: number; skip?: number },
    { rejectWithValue },
  ) => {
    try {
      const members: any = await MemberService.findAll(params)
      return members
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findOne = createAsyncThunk(
  Actions.MEMBER_FIND_ONE,
  async (id: number, { rejectWithValue }) => {
    try {
      return await MemberService.findOne({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateMember = createAsyncThunk(
  Actions.MEMBER_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateMemberDto },
    { rejectWithValue },
  ) => {
    try {
      return await MemberService.update({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createMember = createAsyncThunk(
  Actions.MEMBER_CREATE,
  async (body: CreateMemberDto, { rejectWithValue }) => {
    try {
      return await MemberService.create({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteMember = createAsyncThunk(
  Actions.MEMBER_DELETE,
  async (id: number | number[], { rejectWithValue }) => {
    try {
      return await MemberService.delete({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
