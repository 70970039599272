import {
  AmbulanceCompanyDto,
  PatientTransportDto,
  CreateAmbulanceCompanyDto,
  CreatePatientTransportDto,
  IRequestConfig,
  PaginatedQuery,
  axios,
  getConfigs,
  MedicalOrder,
} from './api'
import { PaginatedDto } from './extendedType'
import queryString from 'querystring'

export interface FindAmbulanceCompaniesQuery extends PaginatedQuery {
  search?: string
  siteId?: number
  siteCode?: string
}

export interface FindAmbulanceDriversQuery extends PaginatedQuery {
  search?: string
  companyId?: number
}

export class AmbulanceService {
  private static readonly endpoint = 'ambulances'

  static getCompanies(
    query: FindAmbulanceCompaniesQuery,
  ): Promise<PaginatedDto<AmbulanceCompanyDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + '?' + queryString.stringify({ ...query }),
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static createCompany(
    dto: CreateAmbulanceCompanyDto,
  ): Promise<AmbulanceCompanyDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }

  static updateAmbulanceCompany(
    id: number,
    dto: Partial<CreateAmbulanceCompanyDto>,
  ): Promise<AmbulanceCompanyDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }

  static deleteCompanies(ids: number[]): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}`,
        {},
      )

      configs.data = Array.isArray(ids) ? ids : [ids]
      axios(configs, resolve, reject)
    })
  }

  static createPatientTransport(
    dto: CreatePatientTransportDto,
  ): Promise<MedicalOrder> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/patient-transports`,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }

  static updatePatientTransport(
    id: number,
    dto: Partial<CreatePatientTransportDto>,
  ): Promise<PatientTransportDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/patient-transports/${id}`,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }

  static deletePatientTransports(ids: number[]): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/patient-transports`,
        {},
      )

      configs.data = Array.isArray(ids) ? ids : [ids]
      axios(configs, resolve, reject)
    })
  }

  static findPatientTransports(
    query: FindAmbulanceDriversQuery,
  ): Promise<PaginatedDto<PatientTransportDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/patient-transports?${queryString.stringify({
          ...query,
        })}`,
        {},
      )

      axios(configs, resolve, reject)
    })
  }
}
