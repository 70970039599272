/**
 * Referentiels
 */

export type GenderType =
  | 'MALE'
  | 'FEMALE'
  | 'TRANSGENDERMALETOFEMALE'
  | 'TRANSGENDERFEMALETOMALE'
  | 'OTHER'
  | 'UNREPORTED'

export const studySharingExpirations = [
  { value: 0, label: 'No Expiration' },
  { value: 1, label: '1 week' },
  { value: 2, label: '2 weeks' },
  { value: 3, label: '3 weeks' },
  { value: 4, label: '1 month' },
  { value: 8, label: '2 month' },
]

export const studySharingPermissions = [
  { value: 'VIEW', label: 'View' },
  { value: 'VIEW_AND_REPORT', label: 'View and Report' },
  { value: 'EXPORT', label: 'Export' },
]

export const sex = [
  { value: '', label: 'All' },
  { value: 'F', label: 'Female' },
  { value: 'M', label: 'Male' },
  { value: 'O', label: 'Other' },
  { value: 'U', label: 'Unknown' },
]

export const scannerStudy = [
  { value: '', label: '' },
  { value: 'scanner', label: 'Scanner' },
  { value: 'coloscanner', label: 'Coloscanner' },
  { value: 'arthroscanner', label: 'Arthroscanner' },
  { value: 'angioscan', label: 'Angioscan' },
  { value: 'uroscanner', label: 'Uroscanner' },
  { value: 'infiltration', label: 'Infiltration' },
  { value: 'uroscanner', label: 'Uroscanner' },
  { value: 'orl', label: 'ORL' },
]

export const bodyPart = [
  { value: '', label: 'all' },
  { value: 'cerebral', label: 'cerebral' },
  { value: 'crane', label: 'crane' },
  { value: 'bassin', label: 'bassin' },
  { value: 'cheville', label: 'cheville' },
  { value: 'coude', label: 'coude' },
  { value: 'de la face', label: 'de la face' },
  { value: 'epaule', label: 'epaule' },
  { value: 'genou', label: 'genou' },
  { value: 'hanche', label: 'hanche' },
  { value: 'jambe', label: 'jambe' },
  { value: 'main', label: 'main' },
  { value: 'maxillaire', label: 'maxillaire' },
  { value: 'pelvimetrie', label: 'pelvimetrie' },
  { value: 'pied', label: 'pied' },
  { value: 'poignet', label: 'poignet' },
  { value: 'rachis cervical', label: 'rachis cervical' },
  { value: 'rachis dorsal', label: 'rachis dorsal' },
  { value: 'rachis lombaire', label: 'rachis lombaire' },
  { value: 'sinus', label: 'sinus' },
  { value: 'thoracique', label: 'thoracique' },
  { value: 'abdomino-pelvienne', label: 'abdomino-pelvienne' },
  { value: 'thoraco-abdomino-pelvien', label: 'thoraco-abdomino-pelvien' },
  { value: 'poignet', label: 'poignet' },
]

export const siteType = [
  { value: '', label: 'All' },
  { value: '101', label: 'Centre Hospitalier Régional (C.H.R.)' },
  { value: '114', label: 'Hôpital des armées' },
  { value: '128', label: 'Etablissement de Soins Chirurgicaux' },
  { value: '129', label: 'Etablissement de Soins Médicaux' },
  { value: '131', label: 'Centre de Lutte Contre Cancer' },
  {
    value: '292',
    label: 'Centre Hospitalier Spécialisé lutte Maladies Mentales',
  },
  { value: '355', label: 'Centre Hospitalier (C.H.)' },
  { value: '365', label: 'Etablissement de Soins Pluridisciplinaire' },
  { value: '696', label: 'Groupement de coopération sanitaire de moyens' },
  {
    value: '697',
    label: 'Groupement de coopération sanitaire - Etablissement de santé',
  },
  { value: '698', label: 'Autre Etablissement Loi Hospitalière' },
  { value: '699', label: 'Entité Ayant Autorisation' },
]

export const legalStatus = [
  { value: '', label: 'All' },
  { value: '1', label: 'Etat' },
  { value: '10', label: 'Etablissement Public National d\'Hospitalisation' },
  {
    value: '11',
    label: 'Etablissement Public Départemental d\'Hospitalisation',
  },
  { value: '13', label: 'Etablissement Public Communal d\'Hospitalisation' },
  {
    value: '14',
    label: 'Etablissement Public Intercommunal d\'Hospitalisation',
  },
  { value: '15', label: 'Etablissement Public Régional d\'Hospitalisation' },
  {
    value: '27',
    label: 'Etablissement Public à Caractère Industriel ou Commercial',
  },
  { value: '29', label: 'Groupement de Coopération Sanitaire public' },
  { value: '44', label: 'Régime Maladie des non Salariés non Agricole' },
  { value: '47', label: 'Société Mutualiste' },
  { value: '49', label: 'Autre Organisme Mutualiste' },
  {
    value: '60',
    label: 'Association Loi 1901 non Reconnue d\'Utilité Publique',
  },
  { value: '61', label: 'Association Loi 1901 Reconnue d\'Utilité Publique' },
  { value: '62', label: 'Association de Droit Local' },
  { value: '63', label: 'Fondation' },
  { value: '64', label: 'Congrégation' },
  { value: '65', label: 'Autre Organisme Privé à But non Lucratif' },
  { value: '70', label: 'Personne Physique' },
  { value: '72', label: 'Société A Responsabilité Limitée (S.A.R.L.)' },
  { value: '73', label: 'Société Anonyme (S.A.)' },
  { value: '74', label: 'Société Civile' },
  { value: '75', label: 'Autre Société' },
  { value: '76', label: 'Groupement d\'Intérêt Economique (G.I.E.)' },
  { value: '77', label: 'Autre Organisme Privé à Caractère Commercial' },
  {
    value: '78',
    label: 'Entreprise Unipersonnelle Responsabilité Limitée (E.U.R.L.)',
  },
  { value: '79', label: 'Société Civile de Moyens (S.C.M.)' },
  {
    value: '85',
    label: 'Soc. Exercice Libéral Responsabilité Limitée (S.E.L.A.R.L.)',
  },
  { value: '88', label: 'Société Civile Professionnelle (S.C.P.)' },
  { value: '89', label: 'Groupement de Coopération Sanitaire Privéete' },
  {
    value: '91',
    label: 'Société Exercice Libéral par Actions Simplifiée (S.E.L.A.S.)',
  },
  { value: '95', label: 'Société par Actions Simplifiée (S.A.S.)' },
]

// ***************** Exams *****************
export const examDuration = [
  { value: 5, label: '5 min' },
  { value: 10, label: '10 min' },
  { value: 15, label: '15 min' },
  { value: 20, label: '20 min' },
  { value: 25, label: '25 min' },
  { value: 30, label: '30 min' },
  { value: 45, label: '45 min' },
  { value: 60, label: '1h' },
]
// *****************STAFF position*****************
// ***************** Job list *****************
export const memberJob = [
  { value: 'healthExecutive', label: 'Health Executive' },
  { value: 'executiveDirecto', label: 'Executive Directo' },
  { value: 'financeDirector', label: 'Finance Director' },
  { value: 'accountant', label: 'Accountant' },
  { value: 'computerTechnician', label: 'Computer Technician' },
  { value: 'security', label: 'Security' },
  { value: 'RSSI', label: 'RSSI' },
  { value: 'DSI', label: 'DSI' },
  { value: 'secretary', label: 'Secretary' },
  { value: 'radiographer', label: 'Radiographer' },
  { value: 'serviceProvider', label: 'Service Provider' },
  { value: 'ITProvider', label: 'ITProvider' },
  { value: 'manager', label: 'Manager' },
  { value: 'radiologist', label: 'Radiologist' },
  { value: 'headRadiology', label: 'Head of Radiology' },
  { value: 'qualityManager', label: 'Quality Manager' },
  { value: 'headEmergencies', label: 'Head of Emergencies' },
  { value: 'biomedicalEngineer', label: 'Biomedical Engineer' },
]

// ***************** Medical Imaging Specialities *****************\
export const radiologistSpecialities = [
  { value: '1', label: 'Oncology' },
  { value: '2', label: 'Pediatric' },
  { value: '3', label: 'MRI MSK' },
  { value: '4', label: 'Neuro Radio' },
  { value: '5', label: 'Vascular' },
  { value: '6', label: 'CT' },
  { value: '7', label: 'MRI' },
  { value: '8', label: 'Conventional radiology' },
]

export const medicalImagingSpecialities = [
  { value: '1', label: 'Conventional Radiology' },
  { value: '2', label: 'CT' },
  { value: '3', label: 'MR' },
  { value: '4', label: 'MG' },
  { value: '5', label: 'US' },
  { value: '6', label: 'CR-Dx' },
  { value: '7', label: 'NM' },
  { value: '8', label: 'PET' },
]

// *********** CHARTS ************

export const chartActivitySelect = [
  { value: '1', label: 'Number of Studies' },
  { value: '2', label: 'Top list of Refering physician' },
  { value: '3', label: 'Top list of Portal user' },
  { value: '4', label: 'Studies per modality' },
  { value: '5', label: '% of Studies booked Online' },
  { value: '6', label: '% of Studies with Admission' },
  { value: '7', label: 'Volume of Data Sent by customer' },
]

export const chartSecuritySelect = [
  { value: '1', label: 'Accounts unlocked' },
  { value: '2', label: 'Accounts login from different IP' },
  { value: '3', label: 'Prescritpion and Studies linked with deprecated RPPS' },
  {
    value: '4',
    label: 'Prescription and Studies linked with Doctors* without RPPS',
  },
]

export const chartPerformanceSelect = [
  { value: '1', label: 'Report Validattion Time(*acquisition)' },
  { value: '2', label: 'Transfer Local Time ' },
  { value: '3', label: 'Transfer Cloud Time' },
  { value: '4', label: 'Transfer Time' },
  { value: '5', label: 'Report Validation Time (*reception)' },
  { value: '6', label: 'Protocol Generation' },
  { value: '7', label: 'Rejected Studies (because of low quality)' },
  { value: '8', label: 'Number of Studies' },
]

export const chartSelectTop_10_100 = [
  { value: '1', label: '10' },
  { value: '2', label: '50' },
  { value: '3', label: '100' },
]

export const chartSelectTop_20_100 = [
  { value: '1', label: '20' },
  { value: '2', label: '50' },
  { value: '3', label: '100' },
]

export const chartSelectTelemedecine = [
  { value: '1', label: 'Xray' },
  { value: '2', label: 'CT' },
  { value: '3', label: 'MR' },
  { value: '3', label: 'CT + MR' },
]

// ***************** EQUIPMENTS *****************
// ***************** IT Equipment List *****************

export const itEquipmentType = [
  { value: '', label: 'All' },
  { value: 'server', label: 'Server' },
  { value: 'vpn', label: 'VPN' },
  { value: 'router', label: 'Router' },
  { value: 'switch', label: 'Switch' },
  { value: 'tv', label: 'TV' },
  { value: 'other', label: 'Other' },
]
// ***************** Medical Equipment List *****************

export const medicalEquipmentType = [
  { value: 'CP', label: 'Capteur plan' },
  { value: 'US', label: 'Echographe' },
  { value: 'EOS', label: 'EOS' },
  { value: 'NM', label: 'Gamma Camera' },
  { value: 'MR', label: 'IRM' },
  { value: 'LINAC', label: 'Linac' },
  { value: 'MG', label: 'Mammographe' },
  { value: 'BDUS', label: 'Osteodentisometre (ultrasound)' },
  { value: 'BMD', label: 'Osteodentisometre (X-Ray)' },
  { value: 'OT', label: 'Other' },
  { value: 'PT', label: 'PET Scan' },
  { value: 'RCT', label: 'Remote controlled table' },
  { value: 'CT', label: 'Scanner' },
]

// ***************** Manufacturer List *****************
export const equipmentBrand = [
  { value: '', label: 'All' },
  { value: 'canon', label: 'Canon' },
  { value: 'cyberknife', label: 'CyberKnife' },
  { value: 'dms-appelem', label: 'DMS / Appelem' },
  { value: 'dms', label: 'DMS' },
  { value: 'elekta', label: 'Elekta' },
  { value: 'eos', label: 'EOS' },
  { value: 'fona', label: 'Fona' },
  { value: 'fuji', label: 'FujiFilm' },
  { value: 'giotto', label: 'Giotto' },
  { value: 'ge', label: 'General Electric' },
  { value: 'gmm', label: 'GMM' },
  { value: 'hitachi-fuji', label: 'Hitachi / FujiFilm' },
  { value: 'hologic', label: 'Hologic' },
  { value: 'mindray', label: 'Mindray' },
  { value: 'neuesoft', label: 'NeueSoft' },
  { value: 'philips', label: 'Philips' },
  { value: 'samsung-emdison', label: 'Samsung / Medison' },
  { value: 'sectra', label: 'Sectra' },
  { value: 'siemens', label: 'Siemens' },
  { value: 'sirona', label: 'Sirona' },
  { value: 'spectrum-dynamic', label: 'Spectrum Dynamic' },
  { value: 'stephanix', label: 'Stephanix' },
  { value: 'tomotherapy', label: 'Tomotherapy' },
  { value: 'carestream', label: 'Carestream' },
  { value: 'newtom', label: 'NewTom' },
  { value: 'varian', label: 'Varian' },
]

// ***************** Equipment Model list *****************
// ***************** Scanners *****************
export const EOSModel = [
  { value: '1', label: 'EOS Edge' },
  { value: '2', label: 'EOS System' },
]

export const scannerModelGE = [
  { value: '1', label: 'Revolution Apex' },
  { value: '2', label: 'Revolution Frontier' },
  { value: '3', label: 'Revolution ACT' },
  { value: '4', label: 'Revolution HD' },
  { value: '5', label: 'Optima' },
  { value: '6', label: 'Discovery RT' },
  { value: '7', label: 'Other' },
]

export const scannerModelSiemens = [
  { value: '1', label: 'Somatom Force' },
  { value: '2', label: 'Somatom Drive' },
  { value: '3', label: 'Somatom X-Cite' },
  { value: '4', label: 'Somatom Edge Plus' },
  { value: '5', label: 'Somatom Definition' },
  { value: '6', label: 'Other' },
]

export const scannerModelCanon = [
  { value: '1', label: 'Aquilion ONE' },
  { value: '2', label: 'Aquilion Prime' },
  { value: '3', label: 'Aquilion Lightning' },
  { value: '4', label: 'Aquilion Exceed' },
  { value: '5', label: 'Aquilion LB' },
  { value: '6', label: 'Other' },
]

export const scannerModelPhilips = [
  { value: '1', label: 'IQon' },
  { value: '2', label: 'Incisive CT' },
  { value: '3', label: 'Ingenuity' },
  { value: '4', label: 'MX16evo' },
  { value: '5', label: 'Other' },
]

export const IRMModelGE = [
  { value: '1', label: 'Signa Explorer' },
  { value: '2', label: 'Signa Voyager' },
  { value: '3', label: 'Signa Architect Air 3T' },
  { value: '4', label: 'Signa Premier Air 3T' },
  { value: '5', label: 'Signa Pioneer 3T' },
  { value: '6', label: 'Signa Discovery 3T' },
  { value: '7', label: 'Other' },
]

// ***************** IRM *****************
export const IRMModelSiemens = [
  { value: '1', label: 'Magnetom Sola' },
  { value: '2', label: 'Magnetom Altea' },
  { value: '3', label: 'Magnetom Sempra' },
  { value: '4', label: 'Magnetom Vida 3T' },
  { value: '5', label: 'Magnetom Lumina 3T' },
  { value: '6', label: 'Prisma 3T' },
  { value: '7', label: 'Other' },
]

export const IRMModelCanon = [
  { value: '1', label: 'Vantage Galan 3T' },
  { value: '2', label: 'Vantage Orian' },
  { value: '3', label: 'Vantage Elan' },
  { value: '4', label: 'Vantage Elan Osteo' },
  { value: '5', label: 'Other' },
]

export const IRMModelPhilips = [
  { value: '1', label: 'Ingenia Edition' },
  { value: '2', label: 'Ingenia Ambition' },
  { value: '3', label: 'Other' },
]

// ***************** Mammoograph *****************
export const mamoModelGE = [
  { value: '1', label: 'Pristina' },
  { value: '2', label: 'Serena' },
  { value: '3', label: 'Crystal Nova' },
  { value: '4', label: 'Other' },
]

export const mamoModelSiemens = [
  { value: '1', label: 'Mammomat Revelation' },
  { value: '2', label: 'Mammomat Inspiration' },
  { value: '3', label: 'Mammomat Fusion' },
  { value: '4', label: 'Other' },
]

export const mamoModelFuji = [
  { value: '1', label: 'Amulet' },
  { value: '2', label: 'Innovality' },
  { value: '3', label: 'Other' },
]

export const mamoModelHologic = [
  { value: '1', label: '3Dimension' },
  { value: '2', label: 'Selenia' },
  { value: '3', label: 'Other' },
]

export const mamoModelGiotto = [
  { value: '1', label: '3000' },
  { value: '2', label: '4000' },
  { value: '3', label: 'Other' },
]

// ***************** Echograph *****************
export const echoModelSamsung = [
  { value: '1', label: 'RS85A Prestige' },
  { value: '2', label: 'HS70A' },
  { value: '3', label: 'HM70A' },
  { value: '4', label: 'Other' },
]

export const echoModelToshiba = [
  { value: '1', label: 'Applio i' },
  { value: '2', label: 'Applio a' },
  { value: '3', label: 'Applio 400' },
  { value: '4', label: 'Applio 500' },
  { value: '5', label: 'XarioViamo' },
  { value: '6', label: 'Other' },
]

export const echoModelHitachi = [
  { value: '1', label: 'Arietta' },
  { value: '2', label: 'Lisendo' },
  { value: '3', label: 'Other' },
]

export const echoModelSiemens = [
  { value: '1', label: 'Acuson Sequoia' },
  { value: '2', label: 'Acuson Juniper' },
  { value: '3', label: 'Acuson Redwood' },
  { value: '4', label: 'Acuson NX2' },
  { value: '5', label: 'Acuson NX3' },
  { value: '6', label: 'Other' },
]

export const echoModelPhilips = [
  { value: '1', label: 'Eqip Elite' },
  { value: '2', label: 'Eqip CVX' },
  { value: '3', label: 'Lumify' },
  { value: '4', label: 'Affinity 30/50/70' },
  { value: '5', label: 'CX50' },
  { value: '6', label: 'InnoSight' },
  { value: '7', label: 'ClearVue850' },
  { value: '8', label: 'Other' },
]

export const echoModelSuperSonic = [
  { value: '1', label: 'Mach 20' },
  { value: '2', label: 'Mach30' },
  { value: '3', label: 'AirExplorer' },
  { value: '4', label: 'AirExplorer Ultimate' },
  { value: '5', label: 'Other' },
]

// ***************** Equipment Specs list *****************
export const scannerBarrettes = [
  { value: '1', label: '4' },
  { value: '2', label: '8' },
  { value: '3', label: '16' },
  { value: '4', label: '32' },
  { value: '5', label: '40' },
  { value: '6', label: '64' },
  { value: '7', label: '128' },
  { value: '8', label: '192' },
  { value: '9', label: '192+' },
]

export const linacTps = [
  { value: '1', label: 'Eclipse' },
  { value: '2', label: 'Isogray' },
  { value: '3', label: 'Monaco' },
  { value: '4', label: 'XIO' },
  { value: '5', label: 'Pinnacle' },
  { value: '6', label: 'BrainLab' },
]

export const oncologyInformationSystem = [
  { value: '1', label: 'Aria' },
  { value: '2', label: 'Mosaiq' },
]

// Components ressources
// Mui color components
export const colors = [
  { color: 'blue', hex: '#72adf0' },
  { color: 'brown', hex: '#8d7968' },
  { color: 'green', hex: '#8be68b' },
  { color: 'grey', hex: '#aaaaaa' },
  { color: 'orange', hex: '#e6c485' },
  { color: 'pink', hex: '#f7bcc6' },
  { color: 'purple', hex: '#b270d8' },
  { color: 'red', hex: '#ff7979' },
  { color: 'teal', hex: '#7cd8d8' },
  { color: 'yellow', hex: '#e4e49e' },
]

// Preferences menu
// TODO get from backend
export const patientCallDuration = [
  { value: 1, label: '5s' },
  { value: 2, label: '10s' },
  { value: 3, label: '15s' },
  { value: 4, label: '20s' },
  { value: 5, label: '30s' },
]
export const patientScrollSpeed = [
  { value: 1, label: 'slow' },
  { value: 2, label: 'normal' },
  { value: 3, label: 'fast' },
  { value: 4, label: 'very fast' },
]

export const defaultList = [
  { value: '1', label: 'Choix 1' },
  { value: '2', label: 'Choix 2' },
  { value: '3', label: 'Choix 3' },
  { value: '4', label: 'Choix 4' },
]

export const questionType = [
  { value: 'open', label: 'Open' },
  { value: 'close', label: 'Close' },
  { value: 'date', label: 'Date' },
]

export const ambulanceTaxiOptions = [
  { value: 'ambulance', label: 'Ambulance' },
  { value: 'vsl', label: 'VSL' },
]

export const privateOptions = [
  { value: 'friend', label: 'Proche' },
  { value: 'relative', label: 'Famille' },
  { value: 'other', label: 'Autre' },
]
