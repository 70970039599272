import { createAsyncThunk, } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { PatientDto, PatientService } from '@services/api'

export const fetchPatients = createAsyncThunk(
  Actions.PATIENTS_FIND_ALL,
  async (nan, { rejectWithValue }) => {
    try {
      return await PatientService.findAllv2({ limit: 5, sex: 'M', search: 'Victoire'})
      // return await PatientService.findAll()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const fetchOnePatientById = createAsyncThunk(
  Actions.PATIENT_FIND_ONE,
  async (id: number, { rejectWithValue }) => {
    try {
      return await PatientService.findOne({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updatePatient = createAsyncThunk(
  Actions.PATIENT_UPDATE,
  async (data: PatientDto, { rejectWithValue }) => {
    try {
      return await PatientService.update(data)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

