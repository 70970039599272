import { IntlProvider } from 'react-intl'

import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import { locales } from './locales'
import Notifier from './containers/Notifier'
import Router from './containers/Router'
import { useSelector } from '@state/store'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY || '')

console.log(process.env.REACT_APP_MUI_X_LICENSE_KEY || '')

export default function App() {
  const language =
    process.env.NODE_ENV === 'development'
      ? 'fr'
      : useSelector(({ auth }) => auth.language)

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <IntlProvider locale={language} messages={locales(language)}>
        <SnackbarProvider>
          <Router />
          <Notifier />
          {/* <AutoLogout /> */}
          {/* <AutoLogoutAlert /> */}
        </SnackbarProvider>
      </IntlProvider>
    </LocalizationProvider>
  )
}
