/* eslint-disable indent */
import { useForm } from 'react-hook-form'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import {
  CreatePatientTransportDto,
  UpdateAmbulanceDriverDto,
  MedicalOrder,
  PatientTransportStatus,
  AmbulanceCompanyDto,
} from '../../../services/api'
import { useSelector } from '../../../state/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import {
  createPatientTransport,
  findAmbulanceCompanies,
  updatePatientTransport,
} from '../../../state/thunks/ambulanceThunk'
import { AmbulanceForm } from './AmbulanceForm'
import theme from '@utils/theme'
import { DialogTitle } from '../../../components/titles'
import moment from 'moment'
import { capitalize } from 'lodash'
import { TextInput } from '@ether-healthcare/inputs'
import { FormattedMessage, useIntl } from 'react-intl'
import { setFilters } from '@state/reducers/ambulanceReducer'
import { privateOptions, ambulanceTaxiOptions } from '@utils/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientTransportSchema, privateTransportSchema } from '@utils/schemas'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

type AddDriverCompanyProps = {
  row: MedicalOrder
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
  apiKey?: string
  siteCode?: string
}

export const renderMedicalOrderPickupStatus = (
  medicalOrder: any,
): React.ReactNode => {
  if (!medicalOrder.patientTransport) return null
  if (medicalOrder.patientTransport.status === PatientTransportStatus.Called) {
    if (medicalOrder.exam.isAutoPickupEnabled) {
      return (
        <CheckIcon
          style={{
            backgroundColor: 'lightgreen',
            borderRadius: '50%',
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
          }}
        />
      )
    } else {
      const call = medicalOrder.patientTransport?.transportCalls?.[0]
      return (
        <Chip
          color="primary"
          label={`Appelé à ${moment(
            medicalOrder.patientTransport.calledAt,
          ).format('HH:mm')} ${
            call?.member
              ? `par ${call.member.firstName} ${call.member.lastName}`
              : ''
          }`}
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      )
    }
  } else {
    if (medicalOrder.exam.isAutoPickupEnabled) {
      return (
        <CloseIcon
          style={{
            backgroundColor: 'orange',
            borderRadius: '50%',
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
          }}
        />
      )
    } else {
      return (
        <Chip
          color="success"
          label="Non appelé"
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      )
    }
  }
}

export function TransportForm({
  row,
  onClose,
  siteCode,
}: AddDriverCompanyProps) {
  const [transportLinkType, setTransportLinkType] = useState('ambulance/taxi')

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<CreatePatientTransportDto>({
    resolver: yupResolver(
      transportLinkType === 'ambulance/taxi'
        ? patientTransportSchema
        : privateTransportSchema,
    ),
    defaultValues: {
      ...row?.patientTransport,
      companyId: row?.patientTransport?.ambulanceCompanyId,
    },
  })
  const [isEditing, setIsEditing] = useState(!row?.patientTransport?.id)

  const dispatch = useAppDispatch()

  const companies = useSelector((state) => state.ambulance.data)

  const [isAmbulanceFormOpen, setIsAmbulanceFormOpen] = useState(false)
  const filters = useAppSelector(({ ambulance }) => ambulance.filters)
  const intl = useIntl()

  const handleOpenAmbulanceForm = () => {
    setIsAmbulanceFormOpen(true)
  }
  const handleCloseAmbulanceForm = () => {
    setIsAmbulanceFormOpen(false)
  }

  const onSubmit = (values: CreatePatientTransportDto) => {
    const companyId =
      transportLinkType === 'ambulance/taxi' ? values.companyId : null

    if (row.patientTransport?.id) {
      const updateAmbulanceCompanyDto: UpdateAmbulanceDriverDto = {
        name: values.name,
        status: row.patientTransport.status,
        comments: values.comments,
        companyId,
        phoneNumber: values.phoneNumber,
        plateNumber: values.plateNumber,
        transportType: values.transportType,
        transportLinkType,
      }
      dispatch(
        updatePatientTransport({
          id: row.patientTransport.id,
          dto: { ...updateAmbulanceCompanyDto, transportLinkType },
        }),
      ).then(() => {
        setIsEditing(false)
      })
    } else {
      const createAmbulanceCompanyDto: CreatePatientTransportDto = {
        ...values,
        companyId,
        medicalOrderId: row.id,
      }
      dispatch(createPatientTransport(createAmbulanceCompanyDto))
        .unwrap()
        .then(() => {
          setIsEditing(false)
        })
    }
    if (onClose) {
      onClose()
    }
  }

  const updateTransportStatus = (status: PatientTransportStatus) => {
    if (!row.patientTransportId) {
      return
    }

    dispatch(
      updatePatientTransport({ id: row.patientTransportId, dto: { status } }),
    )
  }

  useEffect(() => {
    if (!row) {
      return
    }

    setTransportLinkType(
      row.patientTransport?.transportLinkType || 'ambulance/taxi',
    )
  }, [row])

  useEffect(() => {
    dispatch(setFilters({ page: 0 }))
  }, [])

  useEffect(() => {
    dispatch(findAmbulanceCompanies({ limit: 1000 }))
  }, [filters])

  const ambulanceCompany = watch('companyId')

  const company = useMemo(
    () => companies.find((c) => c.id === ambulanceCompany),
    [ambulanceCompany, companies],
  )

  const renderCompanyInfos = (company: AmbulanceCompanyDto) => {
    return (
      <>
        <TableRow>
          <TableCell>{company.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
            Adresse:
          </TableCell>
          <TableCell>
            {company.address} - {company.zipCode} - {company.city}
          </TableCell>
        </TableRow>
        {company.email && (
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{ fontWeight: 'bold' }}
            >
              Email:
            </TableCell>
            <TableCell>{company.email}</TableCell>
          </TableRow>
        )}
        {company.phone1 && (
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{ fontWeight: 'bold' }}
            >
              Téléphone:
            </TableCell>
            <TableCell>
              {company.phone1} {company.phone2 && <>/ {company.phone2}</>}
            </TableCell>
          </TableRow>
        )}
        {company.transportType && (
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{ fontWeight: 'bold' }}
            >
              Type de transport:
            </TableCell>
            <TableCell>{company.transportType}</TableCell>
          </TableRow>
        )}
      </>
    )
  }

  // return patientTransport.status === PatientTransportStatus.Called ? (
  // ) : (
  //   <Chip
  //     color="secondary"
  //     label="Non appelé"
  //     sx={{
  //       color: 'white',
  //       fontWeight: 'bold',
  //     }}
  //   />
  // )

  return row ? (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      {isEditing ? (
        <Paper sx={{ padding: 4 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                value={transportLinkType}
                onChange={(e) => {
                  clearErrors()
                  setTransportLinkType(e.target.value)
                }}
              >
                <FormControlLabel
                  value="ambulance/taxi"
                  control={<Radio />}
                  label="Ambulance/Taxi"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Personnel"
                />
              </RadioGroup>
            </Grid>

            {transportLinkType === 'ambulance/taxi' && (
              <>
                <Grid item xs={12}>
                  <InputLabel id="company-label">
                    Choisir une société
                  </InputLabel>
                  <Autocomplete
                    disablePortal
                    options={companies}
                    renderInput={(params) => <TextField {...params} />}
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.city} - ${option.zipCode}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(_, value) => {
                      setValue('companyId', value?.id)
                    }}
                    defaultValue={row?.patientTransport?.ambulanceCompany}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleOpenAmbulanceForm}
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    + Ajouter une société
                  </Button>
                </Grid>
              </>
            )}

            {company && (
              <Grid item xs={12}>
                <Typography variant="h6" style={{ marginBottom: '4px' }}>
                  Informations de société
                </Typography>
                <TableContainer component={Paper}>
                  <Table>{renderCompanyInfos(company)}</Table>
                </TableContainer>
              </Grid>
            )}

            {!company?.transportType && (
              <Grid item xs={12}>
                <InputLabel id="transportType">Type de transport</InputLabel>

                <Select
                  {...register('transportType')}
                  defaultValue={row.patientTransport?.transportType}
                  fullWidth
                  style={{
                    height: '40px',
                  }}
                >
                  {transportLinkType === 'ambulance/taxi' &&
                    ambulanceTaxiOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {transportLinkType === 'private' &&
                    privateOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextInput
                size="small"
                variant="outlined"
                name="name"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                size="small"
                variant="outlined"
                name="phoneNumber"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                size="small"
                variant="outlined"
                name="plateNumber"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                size="small"
                variant="outlined"
                name="comments"
                label="Comments"
                control={control}
                errors={errors}
                multiline
                rows={2}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  setIsEditing(false)
                }}
                sx={{
                  width: '300px',
                  color: 'white',
                  backgroundColor: theme.palette.secondary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                Fermer
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                sx={{
                  width: '300px',
                  marginLeft: 4,
                  backgroundColor: '#3f3f3f',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#3f3f3f',
                  },
                }}
              >
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Patient
                  </TableCell>
                  <TableCell>
                    {capitalize(row.patient.firstName)}{' '}
                    {row.patient.lastName.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Date de naissance
                  </TableCell>
                  <TableCell>
                    {moment(row.patient.birthDate).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Examen
                  </TableCell>
                  <TableCell>
                    {row.exam.label} ({row.exam.code})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 style={{ marginTop: 10, marginRight: 4 }}>Transport</h1>
            {renderMedicalOrderPickupStatus(row)}
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Heure de transport:
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {row.pickupTime
                      ? new Date(row.pickupTime).toLocaleString()
                      : 'Non défini'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Nom:
                  </TableCell>
                  <TableCell>{row.patientTransport?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Téléphone:
                  </TableCell>
                  <TableCell>{row.patientTransport?.phoneNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Numéro de plaque:
                  </TableCell>
                  <TableCell>{row.patientTransport?.plateNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Commentaires:
                  </TableCell>
                  <TableCell>{row.patientTransport?.comments}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Type de contact:
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id={row.patientTransport?.transportType || 'Autre'}
                    />
                  </TableCell>
                </TableRow>

                {row.patientTransport?.ambulanceCompanyId &&
                  renderCompanyInfos(row.patientTransport.ambulanceCompany)}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setIsEditing(true)
              }}
              style={{
                marginRight: 4,
                width: '200px',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
              }}
            >
              Modifier
            </Button>
            {row.patientTransport?.status === PatientTransportStatus.Called ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                fullWidth
                onClick={() => {
                  updateTransportStatus(PatientTransportStatus.NotCalled)
                }}
              >
                Marquer comme &ldquo;Non appelé&rdquo;
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: 'aquamarine',
                  '&:hover': {
                    backgroundColor: 'aquamarine',
                  },
                }}
                onClick={() => {
                  updateTransportStatus(PatientTransportStatus.Called)
                }}
              >
                Marquer comme &ldquo;Appelé&rdquo;
              </Button>
            )}
          </div>
        </>
      )}

      <Dialog
        open={isAmbulanceFormOpen}
        onClose={handleCloseAmbulanceForm}
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title={intl.formatMessage({ id: 'addAmbulance' })}
          format
          onClose={handleCloseAmbulanceForm}
        />
        <DialogContent sx={{ background: '#fff', padding: '20px' }}>
          <AmbulanceForm
            onClose={handleCloseAmbulanceForm}
            siteCode={siteCode}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  ) : (
    <></>
  )
}
