import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Container,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material'
import { DialogTitle } from '../../../components/titles'
import theme from '../../../utils/theme'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { DataTable } from '../../../components/tables'
import { useSelector } from '../../../state/store'
import { Text } from '@components/texts'
import { Button } from '@components/buttons'

import {
  deleteAmbulanceCompany,
  findAmbulanceCompanies,
} from '../../../state/thunks/ambulanceThunk'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { AmbulanceForm } from './AmbulanceForm'
import { setFilters } from '@state/reducers/ambulanceReducer'
import { Member } from '@services/api'
import SearchIcon from '@material-ui/icons/Search'
import { useIntl } from 'react-intl'

const columns: GridColumns = [
  {
    field: 'name',
    headerName: 'Nom',
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'Ville',
    flex: 1,
  },
  {
    field: 'zipCode',
    headerName: 'Code postal',
    flex: 1,
  },
  {
    field: 'phone1',
    headerName: 'Téléphone 1',
    flex: 1,
  },
  {
    field: 'phone2',
    headerName: 'Téléphone 2',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email de contact',
    flex: 1,
  },
  { field: 'siret', headerName: 'Siret', flex: 1 },
  // {
  //   field: 'sites',
  //   headerName: 'Sites',
  //   flex: 1,
  //   renderCell: ({ row }) =>
  //     row.sites.map((s) => <span key={s.id}>{s.label}</span>),
  // },
]

const AmbulanceCompanies: React.FC = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const ambulances = useSelector((state) => state.ambulance.data)
  const filters = useSelector((state) => state.ambulance.filters)
  const user = useAppSelector(({ auth }) => auth.user)
  const sites = (user as Member)?.sites

  const sitesOptions: {
    value: string | number
    label: string
  }[] =
    useMemo(() => {
      return sites?.map((site) => ({
        value: site.id,
        label: site.label,
      }))
    }, [sites]) || []

  useEffect(() => {
    setIsLoading(false)
    dispatch(findAmbulanceCompanies({ limit: 20 }))
      .unwrap()
      .then(() => {
        setIsLoading(false)
      })
  }, [filters])

  useEffect(() => {
    const _sites = (user as Member)?.sites
    if (_sites?.length === 1) {
      dispatch(setFilters({ siteId: _sites[0].id }))
    }
  }, [user])

  const onRefresh = useCallback(() => {
    dispatch(findAmbulanceCompanies({ limit: 20 }))
  }, [dispatch])

  useEffect(() => {
    onRefresh()
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }

  const _onDeleteConfirm = () => {
    dispatch(deleteAmbulanceCompany(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  const onSearchChange = (search: string) => {
    setSearch(search)
  }

  const setSearch = useMemo(() => {
    return debounce((search: string) => {
      dispatch(setFilters({ search }))
    }, 300)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(
        setFilters({
          search: '',
          page: 0,
        }),
      )
    }
  }, [])

  return (
    <Container maxWidth={false} style={{ height: '96vh', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '96vh' }}>
        <Grid item container>
          <Paper
            style={{
              width: '100%',
              borderRadius: '0px 25px 25px 25px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '10px',
              }}
            >
              <div>
                <InputLabel id="sitesl">Choisir site(s)</InputLabel>
                <Select
                  size="small"
                  variant="outlined"
                  fullWidth
                  style={{ width: '250px' }}
                  placeholder="site"
                  value={filters.siteId}
                  onChange={(e) => {
                    const value = e.target.value as number
                    dispatch(setFilters({ siteId: value }))
                  }}
                >
                  {sitesOptions.map((site) => (
                    <MenuItem key={site.value} value={site.value}>
                      {site.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div
                style={{
                  marginLeft: '12px',
                }}
              >
                <InputLabel>Chercher</InputLabel>
                <Input
                  onChange={(ev) => {
                    onSearchChange(ev.target.value)
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  placeholder={intl.formatMessage({
                    id: 'search',
                    defaultMessage: 'Search',
                  })}
                  style={{
                    height: '40px',
                    width: '250px',
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '0 10px',
                  }}
                  disableUnderline
                />
              </div>
            </div>

            <DataTable
              columns={columns}
              rows={ambulances}
              detailPanelHeight={100}
              loading={isLoading}
              getRowId={(row) => row.id}
              toolBarProps={{
                title: 'ambulance',
                icon: 'fas fa-ambulance',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one ambulance')
                },
              }}
              form={(props) => (
                <AmbulanceForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
              initialState={{
                pagination: { pageSize: 20 },
              }}
              paginationMode="server"
              pagination
              rowCount={filters.total}
              onPageChange={(page) => {
                dispatch(setFilters({ page }))
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addAmbulance"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <AmbulanceForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteAmbulance"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default AmbulanceCompanies
