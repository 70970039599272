import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  LoginDto,
  AuthService,
  UserService,
  LocalUserRegisterPatientDto,
  LocalUserRegisterFromMailDto,
  LocalUserForgetPasswordDto,
  CreatePhoneAuthMethod,
  PhoneVerifyRequest,
  LocalUserResetPasswordDto,
  LocalUserUpdatePasswordDto,
  LocalUserRegisterDoctorDto,
  UpdatePatientDto,
  PatientService,
  UpdateDoctorDto,
  DoctorService,
} from '@services/api'
import { FirstConnectionDto } from '@services/extendedType'
import StorageService from '@services/storage'
import { loginFulfilled, updateAccount } from '@state/reducers/authReducer'

export const login = createAsyncThunk<unknown, LoginDto, any>(
  'auth/login',
  async (body: LoginDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const { user, token } = await AuthService.login({
        body,
      })
      dispatch(loginFulfilled({ user, token }))
      return fulfillWithValue('auth/login/fulfilled')
    } catch (e: any) {
      if (e.response) {
        dispatch(getCaptcha())
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue('network-error')
    }
  },
)

export const logout = createAsyncThunk<unknown, void, any>(
  'auth/logout',
  async (_, { dispatch, fulfillWithValue }) => {
    await AuthService.logout()
    dispatch({ type: 'LOGOUT' })
    StorageService.removeAuthToken()
    return fulfillWithValue('user-disconnected')
  },
)

export const registerUser = createAsyncThunk<
  unknown,
  LocalUserRegisterPatientDto,
  any
>(
  'auth/register/patient',
  async (
    body: LocalUserRegisterPatientDto,
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      await AuthService.createUserFirstConnection({
        body,
      })
      return fulfillWithValue('user-check-email')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const registerDoctor = createAsyncThunk<
  unknown,
  LocalUserRegisterDoctorDto,
  any
>(
  'auth/register/doctor',
  async (
    body: LocalUserRegisterDoctorDto,
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      await AuthService.createDoctorFirstConnection({
        body,
      })
      return fulfillWithValue('user-check-email')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const firstConnexion = createAsyncThunk<
  unknown,
  FirstConnectionDto,
  any
>(
  'auth/first-connexion',
  async (params: FirstConnectionDto, { rejectWithValue }) => {
    try {
      const patient = await AuthService.isValidFirstConnection(params)
      return { ...params, id: patient.id }
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const verifyEmail = createAsyncThunk<unknown, any, any>(
  'auth/verify-email',
  async (
    body: LocalUserRegisterFromMailDto,
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      await AuthService.createLocalUserFromEmail({ body })
      return fulfillWithValue('user-register-success')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const updateEmail = createAsyncThunk<
  unknown,
  LocalUserForgetPasswordDto,
  any
>(
  'auth/update-email',
  async (
    body: LocalUserForgetPasswordDto,
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    try {
      const user = await AuthService.updateEmail({ body })
      dispatch(
        updateAccount({
          email: user.authMethod.local.email,
        }),
      )
      return fulfillWithValue('email-uptade-succes')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const updatePhone = createAsyncThunk<
  unknown,
  CreatePhoneAuthMethod,
  any
>(
  'auth/update-phone',
  async (
    body: CreatePhoneAuthMethod,
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      await UserService.sendPhoneVerification({ body })
      // return fulfillWithValue('You will receive a code by SMS')
      return fulfillWithValue('confirmation-sms-sent')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)
export const verifyCode = createAsyncThunk<unknown, PhoneVerifyRequest, any>(
  'auth/verify-code',
  async (
    body: PhoneVerifyRequest,
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    try {
      const user = await UserService.phoneVerify({ body })
      dispatch(
        updateAccount({
          phone: user.authMethod.phone?.phoneNumber,
        }),
      )
      return fulfillWithValue('phone-update-success')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const forgottenPassword = createAsyncThunk<unknown, any, any>(
  'auth/forgotten-password',
  async (
    params: LocalUserForgetPasswordDto,
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      await AuthService.forgottenPassword(params)
      return fulfillWithValue('password-reset-email')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)
export const resetPassword = createAsyncThunk<unknown, any, any>(
  'auth/reset-password',
  async (
    params: { id: number; body: LocalUserResetPasswordDto },
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      AuthService.resetPassword(params)
      return fulfillWithValue('password-reset-success')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)
export const updatePassword = createAsyncThunk<
  unknown,
  { id: number; body: LocalUserUpdatePasswordDto },
  any
>(
  'auth/update-password',
  async (
    params: { id: number; body: LocalUserUpdatePasswordDto },
    { rejectWithValue, fulfillWithValue },
  ) => {
    try {
      AuthService.updatePassword(params)
      return fulfillWithValue('password-update-succes')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const findMyInfos = createAsyncThunk<unknown, void, any>(
  'user/findMyInfos',
  async (_, { rejectWithValue }) => {
    try {
      const user = await UserService.findMyInfo()
      return user
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const getCaptcha = createAsyncThunk<unknown, void, any>(
  'auth/captcha',
  async (_, { rejectWithValue }) => {
    try {
      const captcha = await AuthService.getCaptcha()
      return { captcha } //disable sucess message
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue('network-error')
    }
  },
)

export const updatePatient = createAsyncThunk<
  unknown,
  { id: number; body: UpdatePatientDto },
  any
>(
  'patient/update',
  async (
    params: { id: number; body: UpdatePatientDto },
    { rejectWithValue },
  ) => {
    try {
      return await PatientService.update(params)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const updateDoctor = createAsyncThunk<
  unknown,
  { id: number; body: UpdateDoctorDto },
  any
>(
  'doctor/update',
  async (
    params: { id: number; body: UpdateDoctorDto },
    { rejectWithValue },
  ) => {
    try {
      return await DoctorService.update(params)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)

export const requestToken = createAsyncThunk<unknown, string, any>(
  'auth/generateToken',
  async (key, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const { user, token } = await AuthService.requestToken(key)
      dispatch(loginFulfilled({ user, token }))
      return fulfillWithValue('auth/login/fulfilled')
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)
