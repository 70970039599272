import React, { useEffect, useState } from 'react'
import { useSelector } from '../../../state/store'
import { useAppDispatch } from '../../../hooks/reduxHooks'
import { findMedicalOrders } from '../../../state/thunks/medicalOrderThunk'
import { useLocation } from 'react-router-dom'
import { TransportForm } from './TransportForm'
import { LinearProgress } from '@mui/material'
import { requestToken } from '@state/thunks/authThunk'
import StorageService from '@services/storage'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { setFilters } from '@state/reducers/ambulanceReducer'

const MedicalOrderTransport: React.FC = () => {
  const medicalOrder = useSelector((state) => state.medicalOrder.medicalOrders)

  const { isAuth } = useSelector(({ auth }) => ({ isAuth: auth.isAuth }))

  const [isLoading, setIsLoading] = useState(false)
  const [siteCode, setSiteCode] = useState('')

  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const search = location.search
    const params = new URLSearchParams(search)
    const accessionNumber = params.get('accessionNumber')
    const _siteCode = params.get('siteCode')
    const key = params.get('key')

    if (!accessionNumber) {
      console.error(
        'Accession number code is missing',
        accessionNumber,
        _siteCode,
      )

      dispatch(
        enqueueSnackbar({
          type: 'error',
          message: 'Accession number code is missing',
        }),
      )
      return
    }
    if (!_siteCode) {
      dispatch(
        enqueueSnackbar({
          type: 'error',
          message: 'Site code is missing',
        }),
      )
      return
    }
    setSiteCode(_siteCode)
    if (!key) {
      dispatch(
        enqueueSnackbar({
          type: 'error',
          message: 'API key is missing',
        }),
      )
      return
    }
    StorageService.setApiKey(key)
    dispatch(setFilters({ siteCode: _siteCode }))

    if (!isAuth) {
      dispatch(requestToken(key))
    }

    setIsLoading(true)
    dispatch(
      findMedicalOrders({
        accessionNumber,
        siteCode: _siteCode,
        includeTransport: true,
        ...(!isAuth && { apiKey: key }),
      }),
    ).then(() => {
      setIsLoading(false)
    })
  }, [isAuth])

  return !isLoading && medicalOrder.length ? (
    <TransportForm siteCode={siteCode} row={medicalOrder[0]} />
  ) : (
    <LinearProgress />
  )
}

export default MedicalOrderTransport
