import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { ambulanceCompanySchema } from '../../../utils/schemas'
import {
  AmbulanceCompanyDto,
  CreateAmbulanceCompanyDto,
  Member,
  UpdateAmbulanceCompanyDto,
} from '../../../services/api'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { findAll } from '../../../state/thunks/siteThunk'
import { Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { Button } from '../../../components/buttons'
import {
  createAmbulanceCompany,
  updateAmbulanceCompany,
} from '../../../state/thunks/ambulanceThunk'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { TextInput } from '@components/inputs'
import { privateOptions, ambulanceTaxiOptions } from '@utils/constants'

type AddAmbulanceCompanyProps = {
  row?: AmbulanceCompanyDto
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
  siteCode?: string
}

export function AmbulanceForm({
  row,
  onClose,
  siteCode,
}: AddAmbulanceCompanyProps) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateAmbulanceCompanyDto>({
    resolver: yupResolver(ambulanceCompanySchema),
    defaultValues: {
      ...row,
      siteIds: row?.sites.map((site) => site.id) || [],
      siteCode,
    },
  })

  const [transportLinkType, setTransportLinkType] = useState('ambulance/taxi')

  const dispatch = useAppDispatch()

  const user = useAppSelector(({ auth }) => auth.user)
  const sites = (user as Member)?.sites

  const sitesOptions: {
    value: string | number
    label: string
  }[] =
    useMemo(() => {
      if (!sites) return []

      return sites.map((site) => ({
        value: site.id,
        label: site.label,
      }))
    }, [sites]) || []

  useEffect(() => {
    dispatch(findAll())
  }, [])

  useEffect(() => {
    if (!row) {
      return
    }

    const r = privateOptions.find(({ value }) => value === row.transportType)
      ? 'private'
      : 'ambulance/taxi'

    setTransportLinkType(r)
  }, [row])

  const onSubmit = (values: CreateAmbulanceCompanyDto) => {
    if (row?.id) {
      const updateAmbulanceCompanyDto: UpdateAmbulanceCompanyDto = {
        ...values,
      }
      dispatch(
        updateAmbulanceCompany({ id: row.id, dto: updateAmbulanceCompanyDto }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Ambulance mise à jour avec succès',
              type: 'success',
            }),
          )
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              message: error.message,
              type: 'error',
            }),
          )
        })
    } else {
      const createAmbulanceCompanyDto: CreateAmbulanceCompanyDto = {
        ...values,
      }
      dispatch(createAmbulanceCompany(createAmbulanceCompanyDto))
        .unwrap()
        .then(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Ambulance créée avec succès',
              type: 'success',
            }),
          )
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              message: error.message,
              type: 'error',
            }),
          )
        })
    }
    if (onClose) {
      onClose()
    }
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={6}>
          {sitesOptions.length ? (
            <>
              <InputLabel id="sitesl">Choisir site(s)</InputLabel>
              <Select
                multiple
                {...register('siteIds')}
                defaultValue={row?.sites?.map((site) => site.id) || []}
                fullWidth
              >
                {sitesOptions.map((site) => (
                  <MenuItem key={site.value} value={site.value}>
                    {site.label}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <>
              <InputLabel id="sitesl">Site</InputLabel>
              <Typography fontWeight="bold">{siteCode}</Typography>
            </>
          )}
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="transportType">Type de société</InputLabel>
          <Select
            {...register('transportType')}
            defaultValue={row?.transportType}
            variant="outlined"
            fullWidth
          >
            {transportLinkType === 'ambulance/taxi' &&
              ambulanceTaxiOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            {transportLinkType === 'private' &&
              privateOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          {/* <InputLabel htmlFor="name">Nom</InputLabel> */}
          <TextInput
            id="name"
            name="name"
            label="Nom"
            variant="outlined"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="phone1"
            variant="outlined"
            type="tel"
            errors={errors}
            control={control}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="phone2"
            type="tel"
            variant="outlined"
            errors={errors}
            control={control}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name="email"
            type="email"
            variant="outlined"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            name="siret"
            errors={errors}
            control={control}
            // limit to 14 characters
            inputProps={{ maxLength: 14 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            name="address"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="outlined"
            name="zipCode"
            type="number"
            errors={errors}
            control={control}
            inputProps={{ maxLength: 5 }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            variant="outlined"
            name="city"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            {onClose && (
              <Grid item xs={2}>
                <Button
                  onClick={handleCancelPress}
                  color="secondary"
                  text="cancel"
                  textColor="white"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <Button
                onClick={handleSubmit(onSubmit)}
                color="primary"
                text="save"
                textColor="white"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
