import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PatientDto } from '@services/api'
import { fetchOnePatientById, fetchPatients, } from '@state/thunks/patientsThunk'

type PatientsState = {
  patientsIds: number[];
  PatientInfo: PatientDto[] | null;
  currentPatientId: number;
  currentPatientInfo: PatientDto | null;
};

const initialState: PatientsState = {
  patientsIds: [],
  PatientInfo: null,
  currentPatientId: 0,
  currentPatientInfo: null
}

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<PatientDto>) => {
      state.patientsIds.push(action.payload.id)
    },
    getPatient: (state, action: PayloadAction<number[]>) => {
      state.currentPatientId = action.payload[0]
      state.patientsIds = action.payload.map((patientId: number) => patientId)
      console.log('test ici2', state.patientsIds)
    },
    getPatientById: (state, action: PayloadAction<number>) => {
      state.currentPatientId = action.payload
      console.log('test ici1', state.patientsIds)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatients.fulfilled, (state, action) => {
      console.log('fonctionne')
      // state.patientsIds = action.payload.map((patient: PatientDto) => patient.id)
    })
    builder.addCase(fetchOnePatientById.fulfilled, (state, action) => {
      state.currentPatientInfo = action.payload as PatientDto
    }
    )}
})

export const { addPatient, getPatient } = patientsSlice.actions

export default patientsSlice.reducer