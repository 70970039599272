import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MedicalOrder } from '../../services/api'
import {
  findMedicalOrders,
  updateMedicalOrder,
} from '../thunks/medicalOrderThunk'
import {
  createPatientTransport,
  updatePatientTransport,
} from '../thunks/ambulanceThunk'

export interface MedicalOrderState {
  medicalOrders: MedicalOrder[]
  filters: {
    search?: string
    accessionNumber?: string
    clientId?: string
    page: number
    limit: number
    total: number
    siteCode?: string
  }
}

const initialState: MedicalOrderState = {
  medicalOrders: [],
  filters: {
    limit: 20,
    page: 0,
    total: 0,
    search: '',
    siteCode: '',
  },
}

export const medicalOrderSlice = createSlice({
  name: 'medicalOrder',
  initialState,
  reducers: {
    setMedicalOrderFilters: (
      state,
      action: PayloadAction<{
        limit: number
        page: number
        total: number
        search?: string
        siteCode?: string
      }>,
    ) => {
      state.filters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findMedicalOrders.fulfilled, (state, action) => {
        state.medicalOrders = action.payload.datas
        state.filters = {
          ...state.filters,
          total: action.payload.totalCount,
        }
      })
      .addCase(createPatientTransport.fulfilled, (state, action) => {
        const index = state.medicalOrders.findIndex(
          (mo) => mo.id == action.payload.id,
        )
        if (index !== -1) {
          state.medicalOrders[index] = action.payload
        }
      })
      .addCase(updatePatientTransport.fulfilled, (state, action) => {
        state.medicalOrders = state.medicalOrders.map((mo) => {
          if (mo.patientTransportId === action.payload.id) {
            return { ...mo, patientTransport: action.payload }
          }
          return mo
        })
      })
      .addCase(updateMedicalOrder.fulfilled, (state, action) => {
        const index = state.medicalOrders.findIndex(
          (mo) => mo.id === action.payload.id,
        )
        state.medicalOrders[index] = action.payload
      })
  },
})

export const { setMedicalOrderFilters } = medicalOrderSlice.actions

export default medicalOrderSlice.reducer
